import cx from 'classnames';
import { IoClose } from "react-icons/io5";

// IoClose
const Modal = props => {
  return <div className="modal-container" onClick={props.onClose}>
    <div className={cx("modal-content", props.className)} onClick={e => e.stopPropagation()}>
      <div className="modal-close" onClick={props.onClose}>
        <IoClose />
      </div>
      {props.children}
    </div>
  </div>
}

export default Modal;