import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { CoinOptions } from '../utils/constants';
import DatePicker from "react-datepicker";
import Loader from './Loader';

const ChartSelection = (props) => {
  const { coinOption, setCoinOption, startDate, setStartDate, endDate, setEndDate,
    checkboxMaxRef, handleCheckMax, handleGenerate, handleClear,
    isDateChanged, handleQuery, error, setError, loading } = props;
  const { t } = useTranslation();
  const changeCoin = (option) => {
    setCoinOption(option)
    console.log(option.value)
  }
  const handleStartDate = (date) => {
    if (error) setError();
    console.log('start Date:', date)
    setStartDate(date)
  }
  const handleEndDate = (date) => {
    if (error) setError();
    setEndDate(date)
  }
  return <>
    <Select
      className="coin-select-container"
      classNamePrefix="coin-select"
      value={coinOption}
      options={CoinOptions}
      onChange={(e) => changeCoin(e)} />
    <div className='chart-select'>
      <div className='chart-select__title'>
        {t('Start Date') + ':'}
      </div>
      <div className='chart-select__select'>
        <DatePicker className='chart-select__select--date'
          selected={startDate}
          onChange={handleStartDate} />
      </div>
    </div>
    <div className='chart-select'>
      <div className='chart-select__title'>
        {t('End Date') + ':'}
      </div>
      <div className='chart-select__select'>
        <DatePicker className='chart-select__select--date'
          selected={endDate}
          onChange={handleEndDate} />
      </div>
    </div>
    {error && <div className='chart-error'>{error}</div>}
    {/* {isDateChanged ? loading ? <div className={classNames('chart-button loading')}>
      <Loader size={'xsmall'} color={'gray'} />
      {t('Updating')}
    </div> : <div className={classNames('chart-button')} onClick={handleQuery}>
      {t('Update Chart')}
    </div> : loading ? <div className={classNames('chart-button loading')}>
      <Loader size={'xsmall'} color={'gray'} />
      {t('Analying')}
    </div> : <div className={classNames('chart-button')} onClick={() => handleGenerate('True')}>
      {t('Show Price')}
    </div>} */}
    {isDateChanged ? loading ? <div className={classNames('chart-button loading')}>
      <Loader size={'xsmall'} color={'gray'} />
      {t('Updating')}
    </div> : <div className={classNames('chart-button')} onClick={() => handleGenerate('True')}>
      {t('Show Price')}
    </div> : loading ? <div className={classNames('chart-button loading')}>
      <Loader size={'xsmall'} color={'gray'} />
      {t('Analying')}
    </div> : <div className={classNames('chart-button')} onClick={() => handleGenerate('True')}>
      {t('Show Price')}
    </div>}
    <div className='chart-button' onClick={handleClear}>
      {t('Clear points')}
    </div>
    <label className='chart-checkbox'>
      <input type='checkbox' ref={checkboxMaxRef} onChange={handleCheckMax} defaultChecked={true} />
      <span className="checkmark"></span>
      {t('Max. increase/decline')}
    </label>
    {/* <div className={classNames('chart-button', { disabled: isDateChanged })} onClick={handleQuery}>
      {t('Query')}
    </div>
    <div className='chart-button' onClick={handleClear}>
      {t('Clear red points')}
    </div> */}
  </>
}

export default React.memo(ChartSelection);
