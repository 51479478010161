export function getDate() {
  // 创建 Date 对象
  const currentDate = new Date();

  // 获取年、月、日、时、分、秒
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并且补零
  const day = String(currentDate.getDate()).padStart(2, '0'); // 需要补零
  const hours = String(currentDate.getHours()).padStart(2, '0'); // 需要补零
  const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // 需要补零
  const seconds = String(currentDate.getSeconds()).padStart(2, '0'); // 需要补零

  // 格式化为指定格式
  const formattedDateTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

  // 输出结果
  console.log('formattedDateTime:', formattedDateTime);
  return formattedDateTime
}

export function getFormattedDate(date = new Date()) {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  function addSuffix(day) {
    if (day === 1 || day === 21 || day === 31) {
      return day + "st";
    } else if (day === 2 || day === 22) {
      return day + "nd";
    } else if (day === 3 || day === 23) {
      return day + "rd";
    } else {
      return day + "th";
    }
  }
  const month = monthNames[date.getMonth()];
  const day = addSuffix(date.getDate());
  const year = date.getFullYear();

  const formattedDate = `${month} ${day}, ${year}`;

  return formattedDate;
}

export const ErrorMsgs = new Set(['SYSTEM ERROR', 'LIMIT ERROR: HOUR', 'LIMIT ERROR: TOTAL', 'LIMIT ERROR: ANONYMOUS'])

export const ErrorMsgsMap = {
  'SYSTEM ERROR': "Something went wrong, please refresh and try again.",
  'LIMIT ERROR: HOUR': "The hourly message limit has been exceeded.",
  'LIMIT ERROR: TOTAL': "The total message limit of your account has been exceeded.",
  'LIMIT ERROR: ANONYMOUS': "You have reached the message limit. Please sign up to continue chatting!",
  'LIMIT ERROR: UNVERIFIED': "You have reached the message limit. Please verify account to continue chatting!"
}