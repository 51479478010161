import React from 'react';

const Switch = ({ checked, onChange, className }) => {
  return (
    <label className={`switch ${checked ? 'on' : 'off'} ${className}`} >
      <input type="checkbox" checked={checked} readOnly />
      <span className="slider" onClick={onChange}></span>
    </label>
  );
}

export default React.memo(Switch);