import React, { useState } from 'react';
import NavBarTop from '../components/NavBarTop';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Button';
import { post } from '../service/fetch';
import classNames from 'classnames';


function SignUpForm({ onSubmit, loading, error, require2FA }) {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();

  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form LoginForm'}
    >
      <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t('USERNAME')}</div>
        <input className='LoginPage__input' type="text" placeholder={t("Enter your username")}
          {...register("username", { required: true, maxLength: 83 })} />
      </label>
      <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t("EMAIL")}</div>
        <input className='LoginPage__input' type="text" placeholder={t("Enter your email")}
          {...register("email", { required: true, maxLength: 83 })} />
      </label>
      {/* <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t("PHONE NUMBER")}</div>
        <input className='LoginPage__input' type="number" placeholder={t("Enter your phone number")}
          {...register("phone", { required: true, maxLength: 20 })} />
      </label> */}
      <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t("PASSWORD")}</div>
        <input className='LoginPage__input' type="password" placeholder={t("Enter your password")}
          {...register("password", { required: true, maxLength: 100 })} />
        <input className='LoginPage__input' type="password" placeholder={t("Re-enter your password")}
          {...register("passwordRe", { required: true, maxLength: 100 })} />
      </label>
      {/* <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t("INVITE CODE")}</div>
        <input className='LoginPage__input' type="text" placeholder={t("Enter your invite code")}
          {...register("inviteCode", { required: true, maxLength: 83 })} />
      </label> */}

      {
        error &&
        <div className={'FormError'}>{error}</div>
      }

      <Button title={'Sign Up'}
        role={'submit'}
        loading={loading}
        className={classNames('LoginPage__button', { 'loading': loading })}
      >{t("Sign Up")}</Button>
    </form>
  );
}

function SignUpPage() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const { username, email, phone = '0000000000', password, passwordRe, inviteCode = '0000000' } = data;
    if (username.length < 5) {
      setError('Username is too short. It must be at least 5 characters long.')
      return;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setError('Invalid email address.')
      return;
    }
    if (phone.length < 10 || phone.length > 11) {
      setError('Invalid phone number.')
      return;
    }
    if (password !== passwordRe) {
      setError('Miss matched password.')
      return;
    }
    if (password.length < 8) {
      setError('Password is too short. It must be at least 8 characters long.')
      return;
    }
    if (inviteCode.length !== 7) {
      setError('Invalid invite code.')
      return;
    }
    try {
      if (error) setError();
      setLoading(true)
      const response = await post({
        url: "/register",
        body: {
          account: username,
          password: password,
          email: email,
          phone: phone,
          invitecode: inviteCode
        }
      })
      console.log('response:', response)
      if (response.status === 'fail') {
        setError(response.message);
        return;
      }
      const { verify_token, user_id, token } = response;
      localStorage.setItem('username', username);
      localStorage.setItem('email', email);
      localStorage.setItem('token', token);
      localStorage.setItem('verify_token', verify_token);
      localStorage.setItem('user-id', user_id);
      navigate("../dashboard");
      // navigate(`../verify?t=${verify_token}&e=${encodeURIComponent(email)}`);
    } catch (err) {
      console.log('error:', err);
      setError(err.message)
    } finally {
      setLoading(false)
    }

  };

  return <div className='LoginPage'>
    <NavBarTop />
    <div className='LoginPage__logo'></div>
    <div className='LoginPage__title'>{t('Sign Up')}</div>
    <div className='LoginPage__form'>
      <SignUpForm onSubmit={onSubmit}
        loading={loading}
        error={error}
      />
    </div>
    <div className='LoginPage__note'>
      <div className='LoginPage__note--raw'>{t('Already have an account?')} <Link to='../login'>{t('Log in')}</Link></div>
    </div>
  </div>
}

export default SignUpPage;
