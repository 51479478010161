import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { LanguageOptions, DefaultLauageOption } from '../utils/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';

function NavBarTop() {
  const { t, i18n } = useTranslation();
  // const [selectedTab, setSelectedTab] = useState('chat');
  const language = JSON.parse(localStorage.getItem('crypto-gpt-language')) || DefaultLauageOption;
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(localStorage.getItem('tabName') || 'chat');

  const changeLanguage = (option) => {
    localStorage.setItem('crypto-gpt-language', JSON.stringify(option))
    i18n.changeLanguage(option.value);
  };

  const handleTab = (tab) => {
    const currentPath = location.pathname;
    if (currentPath === '/request-pwd-reset') {
      navigate('../');
    }
    localStorage.setItem('tabName', tab)
    setSelectedTab(tab);
  }

  return <div className='NavBarTop'>
    <div className={classNames('NavBarTop__section', { 'active': selectedTab === 'home' })} onClick={() => handleTab('home')}>{t('HOME')}</div>
    <div className={classNames('NavBarTop__section', { 'active': selectedTab === 'chat' })} onClick={() => handleTab('chat')}>{t('CHAT')}</div>
    <div className='NavBarTop__section'>
      <Select
        className="language-select-container"
        classNamePrefix="language-select"
        value={language}
        options={LanguageOptions}
        onChange={(e) => changeLanguage(e)} />
    </div>
  </div>
}

export default NavBarTop;
