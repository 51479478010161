import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { get } from '../service/fetch';
import {
  generateLastWeekTime, getTick, getYAxis, getSeries, getXAxiInterval,
  generateVisualMapPieces, formatDateToDay, getAnalysisText,
  ifInDateRange, genarateLastTimeGMT
} from '../utils/chart';
import * as echarts from 'echarts';
import Loader from './Loader';
import { getFormattedDate } from '../utils/helper';
import config from '../config';
import ChartSelection from './ChartSelection';
import { Markup } from 'react-render-markup';

const myUrl = config.chartApi.host + ':' + config.chartApi.port || "http://127.0.0.1:5000";
const axisName = 'Price($)'

const Chart = (props) => {
  const { isFullScreen, handleFullScreen, coinOption, setCoinOption, startDate, setStartDate, endDate, setEndDate,
    chartObj, setChartObj, selectedCrypto, setSelected, cryptoDateRange, setCryptoDateRange, analysisText,
    setAnalysisText, setDateOutOfRangeText, chart, chartElement, checkboxMaxRef,
    selectedPoints, TargetPoints, SpecialPointsValue, SpecialPointsLabel, xAxisData, preDates, setPreDates,
    setChatHistory, socket, curChat, setIsAsking, shouldFetchChatListRef, loading, setLoading,
    hasChartOptions, toggleOptions, isMobile
  } = props;
  // const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [chartError, setChartError] = useState();
  const [isDateChanged, setIsDateChanged] = useState(true);
  const userId = localStorage.getItem('user-id');


  const { t } = useTranslation();

  useEffect(() => {
    console.log('in setIsDateChanged useEffect.')
    console.log('isDateChanged start', +startDate, +preDates[0], +startDate !== +preDates[0])
    console.log('isDateChanged end', +endDate, +preDates[1], +endDate !== +preDates[1])
    console.log('!isDateChanged:', !isDateChanged)
    if (+startDate !== +preDates[0] || +endDate !== +preDates[1]) {
      if (!isDateChanged) {
        console.log('setting isDateChanged to true')
        setIsDateChanged(true);
      }
    } else {
      if (isDateChanged) {
        console.log('setting isDateChanged to false')
        setIsDateChanged(false);
      }
    }
  }, [startDate, endDate, preDates, isDateChanged])

  const createCustomChart = useCallback((data, useLogScale, ifClearPoints, upDownPoints, showanalysis, startDate, endDate) => {
    // if (chart.current) return;

    if (ifClearPoints) {
      selectedPoints.current.clear();
    }
    console.log("create chart!");
    // //console.log(data.map((item) => item.time));
    let minPrice = Math.min(...data.map((item) => item.price));
    let maxPrice = Math.max(...data.map((item) => item.price));
    let tick = getTick(maxPrice, minPrice);
    let dotCnt = 0;
    let dotTick = tick;
    while (dotTick < 1) {
      dotCnt++;
      dotTick = dotTick * 10;
    }
    if (chartElement.current) {
      if (chart.current) chart.current.dispose();
      chart.current = echarts.init(chartElement.current);
      const option = {
        animation: false,
        tooltip: {
          trigger: 'axis',
          // tip文本格式
          formatter: function (params) {
            const time = new Date(params[0].axisValue).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
              timeZone: 'Asia/Shanghai',
            }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2');

            const price = params[0].data;
            let formattedPrice;

            // if (language_type.value == 'ch') {
            //   if (price >= 1000) {
            //     // 对于值大于等于 1000, 仅保留整数部分, 加上“美元”单位
            //     formattedPrice = Math.round(price) + " 美元";
            //   } else if (price >= 10) {
            //     // 对于值在 10 到 1000 之间, 保留两位小数, 加上“美元”单位
            //     formattedPrice = parseFloat(price.toFixed(2)) + " 美元";
            //   } else {
            //     // 对于值小于 10, 保留三位有效数字, 加上“美元”单位
            //     formattedPrice = parseFloat(Number(price).toFixed(dotCnt + 3)) + " 美元";
            //   }
            //   return `时间：${time} </br>价格：${formattedPrice}`;
            // }
            // else {
            if (price >= 1000) {
              // 对于值大于等于 1000, 仅保留整数部分, 加上“美元”单位
              formattedPrice = "$" + Math.round(price).toLocaleString(undefined, { maximumFractionDigits: 0 });
            } else if (price >= 10) {
              // 对于值在 10 到 1000 之间, 保留两位小数, 加上“美元”单位
              formattedPrice = "$" + parseFloat(price.toFixed(2));
            } else {
              // 对于值小于 10, 保留三位有效数字, 加上“美元”单位
              formattedPrice = "$" + parseFloat(Number(price).toFixed(dotCnt + 3));
            }
            return `Time: ${time} </br>Price: ${formattedPrice}`;
            // }


          },
          backgroundColor: 'rgba(50,50,50,0.7)',
          textStyle: {
            color: '#8E92AB'
          },
        },
        grid: {
          left: isMobile ? '15%' : '10%',
        },

        xAxis: {
          type: "category",
          data: data.map((item) => item.time),
          //splitNumber: 10,//getDaysBetweenDates(dateRange.value[0],dateRange.value[1])+1,

          axisLabel: {
            //:TODO 中间时间有缺失
            // interval: getXAxiInterval(startDate, endDate),
            interval: 'auto',
            formatter: function (value) {
              const timestamp = new Date(value);
              return timestamp.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour12: false,
                timeZone: 'Asia/Shanghai',
              }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2').split(",")[0];
            },
            color: '#8E92AB',
          },
          axisLine: {
            lineStyle: {
              color: '#222838',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#222838',
            },
          },
        },
        yAxis: getYAxis(useLogScale, maxPrice, minPrice, axisName),  //得到两个坐标轴 并标记label
        series: getSeries(data, useLogScale, dotCnt, showanalysis, TargetPoints.current, SpecialPointsValue.current, SpecialPointsLabel.current),
      };

      xAxisData.current = option.xAxis.data;

      // TODO: use checkboxUPDOWN ref
      console.log('checkboxMaxRef.current.checked:', checkboxMaxRef.current.checked)
      if (checkboxMaxRef.current.checked) {
        // if (true) {
        // console.log("AAA");
        option.visualMap = {
          show: false,
          dimension: 0,
          pieces: generateVisualMapPieces(upDownPoints, '#2BBA96', '#FF3366', '#FF3366', '#7B74AB')
        };
      } else {
        // console.log("BBB");
        option.visualMap = {
          show: false,
          dimension: 0,
          pieces: generateVisualMapPieces(upDownPoints, '#7B74AB', '#7B74AB', '#7B74AB', '#7B74AB')
        };
      }

      option.series[0].markPoint.data = Array.from(selectedPoints.current).map((index) => ({
        coord: [data[index].time, data[index].price],
      }));

      //chart.current.option.series.clear();
      chart.current.setOption(option);
      setChartObj(obj => ({
        ...obj,
        chartOption: option
      }))

      // 添加点击事件, 点击标红, 避免一次点击多次触发
      chart.current.off('click');
      chart.current.on('click', function (params) {
        if (params.componentType === 'series') {
          // 判断是否点击了折线图
          if (params.seriesType === 'line') {
            const dataIndex = params.dataIndex;

            if (!selectedPoints.current.has(dataIndex)) {
              selectedPoints.current.add(dataIndex);
            }
            console.log("click series" + Array.from(selectedPoints.current))

            option.series[0].markPoint.data = Array.from(selectedPoints.current).map((index) => ({
              coord: [data[index].time, data[index].price],
            }));

            chart.current.setOption(option);
          }
          // 点击的是 point
        } else if (params.componentType === 'markPoint') {
          // console.log("click dataType: "+params.dataType)
          // 点击的是 markPoint
          const index = data.findIndex((item) => item.time === params.data.coord[0]);
          // console.log("click markpoint at："+index)
          if (!selectedPoints.current.has(index)) {
            selectedPoints.current.add(index);
          } else {
            selectedPoints.current.delete(index);
          }
          console.log("click markPoint" + Array.from(selectedPoints.current))

          option.series[0].markPoint.data = Array.from(selectedPoints.current).map((index) => ({
            coord: [data[index].time, data[index].price],
          }));
          chart.current.setOption(option);
          setChartObj(obj => ({
            ...obj,
            chartOption: option
          }))
        }
      });
    }
  }, [SpecialPointsLabel, SpecialPointsValue, TargetPoints, chart, chartElement, checkboxMaxRef, selectedPoints, setChartObj, xAxisData, isMobile])

  const copyCustomChart = useCallback((chartObj) => {
    const { chartData, upDownPoints, chartOption } = chartObj
    if (chartElement.current) {
      if (!chartOption) return;
      if (chart.current) chart.current.dispose();
      chart.current = echarts.init(chartElement.current);
      console.log('chartOption:', chartOption)
      let option = chartOption;
      let data = chartData;
      xAxisData.current = option.xAxis.data;

      console.log('checkboxMaxRef.current.checked:', checkboxMaxRef.current.checked)
      if (checkboxMaxRef.current.checked) {
        // if (true) {
        // console.log("AAA");
        option.visualMap = {
          show: false,
          dimension: 0,
          pieces: generateVisualMapPieces(upDownPoints, '#2BBA96', '#FF3366', '#FF3366', '#7B74AB')
        };
      } else {
        // console.log("BBB");
        option.visualMap = {
          show: false,
          dimension: 0,
          pieces: generateVisualMapPieces(upDownPoints, '#7B74AB', '#7B74AB', '#7B74AB', '#7B74AB')
        };
      }

      console.log('selectedPoints:', selectedPoints)
      option.series[0].markPoint.data = Array.from(selectedPoints.current).map((index) => ({
        coord: [data[index].time, data[index].price],
      }));

      //chart.current.option.series.clear();
      chart.current.setOption(option);
      setChartObj(obj => ({
        ...obj,
        chartOption: option
      }))

      // 添加点击事件, 点击标红, 避免一次点击多次触发
      chart.current.off('click');
      chart.current.on('click', function (params) {
        if (params.componentType === 'series') {
          // 判断是否点击了折线图
          if (params.seriesType === 'line') {
            const dataIndex = params.dataIndex;

            if (!selectedPoints.current.has(dataIndex)) {
              selectedPoints.current.add(dataIndex);
            }
            console.log("click series" + Array.from(selectedPoints.current))

            option.series[0].markPoint.data = Array.from(selectedPoints.current).map((index) => ({
              coord: [data[index].time, data[index].price],
            }));

            chart.current.setOption(option);
          }
          // 点击的是 point
        } else if (params.componentType === 'markPoint') {
          // console.log("click dataType: "+params.dataType)
          // 点击的是 markPoint
          const index = data.findIndex((item) => item.time === params.data.coord[0]);
          // console.log("click markpoint at："+index)
          if (!selectedPoints.current.has(index)) {
            selectedPoints.current.add(index);
          } else {
            selectedPoints.current.delete(index);
          }
          console.log("click markPoint" + Array.from(selectedPoints.current))

          option.series[0].markPoint.data = Array.from(selectedPoints.current).map((index) => ({
            coord: [data[index].time, data[index].price],
          }));
          chart.current.setOption(option);
          setChartObj(obj => ({
            ...obj,
            chartOption: option
          }))
        }
      });
    }
  }, [chart, chartElement, checkboxMaxRef, selectedPoints, setChartObj, xAxisData])

  const fetchData = useCallback(async (crypto, lastStartEndTime, interact) => {
    if (chartError) setChartError();
    try {
      const fetchDataResponse = await get({
        host: myUrl,
        url: '/data',
        params: {
          c: crypto,
          u: interact,
          s: lastStartEndTime[0],
          e: lastStartEndTime[1],
          sa: "False",
          news: true,
        }
      })
      console.log('fetchDataResponse:', fetchDataResponse)
      const results = fetchDataResponse.results;

      let ShowAnalysisValue = fetchDataResponse.showanalysis === 'true';
      let chartData = results;

      let upDownPoints = fetchDataResponse.upDownPoints

      let startDateValue = new Date(results[0].time);
      let endDateValue = new Date(results[results.length - 1].time);

      setStartDate(startDateValue)
      setEndDate(endDateValue)
      setPreDates([startDateValue, endDateValue])

      setChartObj({
        upDownPoints,
        chartData,
        ShowAnalysisValue
      })

      return { ShowAnalysisValue, chartData, upDownPoints, startDateValue, endDateValue };
    } catch (e) {
      console.log('error in fetchData:', e)
      setChartError('Something went wrong, please try again later.')
      if (loading) setLoading(false);
    }
  }, [setChartObj, setEndDate, setStartDate, loading, setLoading])

  useEffect(() => {
    const resize = () => copyCustomChart(chartObj)
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [])

  useEffect(() => {
    console.log('start InitialPage.')
    console.log('isFullscreen:', isFullScreen)
    let lastStartEndTime;
    const fetchCryptos = async () => {
      let cryptos = [];
      try {
        const fetchCryptosResponse = await get({
          host: myUrl,
          url: '/crypto'
        })
        const results = fetchCryptosResponse.results;
        console.log('fetchCryptosResponse:', fetchCryptosResponse)
        const allCryptos = [];
        results.forEach((item, index, array) => {
          for (let i = 0; i < item.length; i++) {
            allCryptos.push(item[i])
          }
        });
        // cryptos.value = []  // 将结果赋值给 cryptos 数组
        results.forEach((item, index, array) => {
          if (item.length === 1)
            cryptos.push([item[0], item[0]])
          else if (item.length === 2)
            cryptos.push([item[0] + " (" + (item[1]) + ")", item[0]])
          else if (item.length === 3) {
            cryptos.push([item[0] + " (" + item[1] + ")", item[0]]);
            cryptos.push([item[2] + "(" + item[1] + ")", item[0]]);
          }
        });
        cryptos.sort();
        return cryptos
      } catch (e) {
        console.log('error in fetchCryptos:', e);
      }

    }

    const InitialPage = async () => {
      let cryptos, cryptoDateRange;
      setLoading(true);
      cryptos = await fetchCryptos();

      try {
        const fetchDateRangeResponse = await get({
          host: myUrl,
          url: '/dateRange'
        })
        cryptoDateRange = fetchDateRangeResponse.results
        setCryptoDateRange(cryptoDateRange);
      } catch (e) {
        console.log('error in fetchDateRange:', e);
        setLoading(false);
        return;
      }

      try {
        // 先生成上周的时间
        lastStartEndTime = generateLastWeekTime("bitcoin", cryptoDateRange);


        let { ShowAnalysisValue, chartData, upDownPoints, startDateValue, endDateValue } = await fetchData('bitcoin', lastStartEndTime, 'True');

        setLoading(false);
        createCustomChart(chartData, false, true, upDownPoints, ShowAnalysisValue, formatDateToDay(startDateValue), formatDateToDay(endDateValue));
        //createCustomChart(chartData, true, true, upDownPoints, ShowAnalysisValue, formatDateToDay(startDateValue), formatDateToDay(endDateValue));
      } catch (e) {
        console.log('error in second part of InitialPage:', e);
        setLoading(false);
      }
    };

    if (chartObj.chartData.length === 0) InitialPage();
    else {
      copyCustomChart(chartObj)
    }

    // }, [isFullScreen])
  }, [isFullScreen])

  const handleCheckMax = () => {
    const { chartData, upDownPoints, ShowAnalysisValue } = chartObj;
    createCustomChart(chartData, false, false, upDownPoints, ShowAnalysisValue, formatDateToDay(startDate), formatDateToDay(endDate));
    //createCustomChart(chartData, true, false, upDownPoints, ShowAnalysisValue, formatDateToDay(startDate), formatDateToDay(endDate));
  }

  const handleSendMsg = () => {
    const msg = `Please analyze the ${coinOption.value} price from ${getFormattedDate(startDate)} to ${getFormattedDate(endDate)}`
    setChatHistory(ch => {
      if (!ch[0].question) return [{ 'question': msg, 'response': 'loading' }]
      else return ch.concat({ 'question': msg, 'response': 'loading' })
    })
    setIsAsking(true);
    return msg;
  }

  const handleReceiveMsg = (question, response) => {
    socket.current.emit('chart-msg', { userId, chatId: curChat ? curChat : "", data: { question, response } });
  }

  const handleClear = () => {
    selectedPoints.current.clear();
    TargetPoints.current = [];
    SpecialPointsValue.current = [];

    setAnalysisText('')
    const { chartData, upDownPoints, ShowAnalysisValue } = chartObj;
    createCustomChart(chartData, false, true, upDownPoints, ShowAnalysisValue, formatDateToDay(startDate), formatDateToDay(endDate))
    //createCustomChart(chartData, true, true, upDownPoints, ShowAnalysisValue, formatDateToDay(startDate), formatDateToDay(endDate))
  }

  const handleGenerate = async (interact) => {
    if (error) setError();
    //const crypto = selectedCrypto;
    const crypto = coinOption.value;
    console.log('!!!!!!!!!!!! :', crypto)
    if (!ifInDateRange(crypto, cryptoDateRange, setDateOutOfRangeText, formatDateToDay(startDate), formatDateToDay(endDate))) {
      setError('Invalid Date.')
      return;
    }

    try {
      const question = handleSendMsg();
      setLoading(true);


      let lastStartEndTime = genarateLastTimeGMT(formatDateToDay(startDate), formatDateToDay(endDate));

      setAnalysisText('');
      chart.current.clear() // 清空图表数据
      // 这里写死的 bitcoin 改一下
      let { ShowAnalysisValue, chartData, upDownPoints, startDateValue, endDateValue } = await fetchData(crypto, lastStartEndTime, interact);
      createCustomChart(chartData, false, false, upDownPoints, ShowAnalysisValue, formatDateToDay(startDateValue), formatDateToDay(endDateValue));
      //createCustomChart(chartData, true, false, upDownPoints, ShowAnalysisValue, formatDateToDay(startDateValue), formatDateToDay(endDateValue));

      // 没有选择红点数据
      const sDate = formatDateToDay(startDate);
      const eDate = formatDateToDay(endDate)

      lastStartEndTime = genarateLastTimeGMT(sDate, eDate);

      // 试试改改这里，应该是申请新闻
      const option = {
        c: crypto,
        u: interact,
        s: lastStartEndTime[0],
        e: lastStartEndTime[1],
        sa: "True",
        regen: "False",
        news: true,
        start_day: sDate,
        end_day: eDate,
      }
      console.log('lastStartEndTime:', lastStartEndTime)
      let pointsTime = [];
      if (selectedPoints.current.size !== 0) {
        [...selectedPoints.current].forEach(function (pointIndex) {
          let time = xAxisData.current[pointIndex];
          pointsTime.push(time);
        });
      }
      if (!pointsTime.includes(xAxisData.current[0]))
        pointsTime.push(xAxisData.current[0]);
      if (!pointsTime.includes(xAxisData.current[xAxisData.current.length - 1]))
        pointsTime.push(xAxisData.current[xAxisData.current.length - 1]);

      console.log('xAxisData.current[0]:', xAxisData.current[0])
      console.log('xAxisData.current[xAxisData.current.length - 1]:', xAxisData.current[xAxisData.current.length - 1])
      console.log('pointsTime:', pointsTime)
      option[`t[]`] = pointsTime;
      option.regen = 'True';
      console.log('option:', option)
      try {
        const fetchDataResponse = await get(({
          url: '/data',
          host: myUrl,
          params: option
        }))
        console.log('fetchDataResponse:', fetchDataResponse)
        let ShowAnalysisValue = fetchDataResponse.showanalysis === 'true';
        // TargetPoints 存放ABCD点的index
        TargetPoints.current = fetchDataResponse.endpoints

        const upDownPoints = fetchDataResponse.upDownPoints

        SpecialPointsValue.current = fetchDataResponse.specialpoints;

        console.log('SpecialPointsValue:', SpecialPointsValue);

        SpecialPointsLabel.current = fetchDataResponse.specialpointsname;

        console.log('SpecialPointsLabel:', SpecialPointsLabel);

        // createChart(chartData, isFullscreen.value, false, false, upDownPoints, ShowAnalysisValue);
        setChartObj({
          chartData,
          upDownPoints, ShowAnalysisValue
        })
        createCustomChart(chartData, false, true, upDownPoints, ShowAnalysisValue, sDate, eDate);
        //createCustomChart(chartData, true, true, upDownPoints, ShowAnalysisValue, sDate, eDate);
        const analysisEn = fetchDataResponse.results_analysis_en;
        const htmlOutput = `
          <meta charset="UTF-8">
          <h3 id="sources">Market insights for the selected date range</h3>
          <p class="result">${analysisEn}</p>
        `;
        const preString = "Sorry, there are no news articles within the specified date range, please consider adjusting the date range.";
        let output = analysisEn === preString ? analysisEn : htmlOutput;
        handleReceiveMsg(question, output);
        setAnalysisText(output);
      } catch (e) {
        console.log('error in handle generate:', e)
      } finally {
        setLoading(false);
      }
    } catch (e) {
      console.log('error in handle generate outer:', e)
    } finally {
      setLoading(false);
      if (isMobile) {
        toggleOptions()
      }
    }
  }

  const handleQuery = async (interact) => {
    //const crypto = selectedCrypto;
    const crypto = coinOption.value;
    console.log('!!!!!!!!!!!! :', crypto)
    //const ShowData = (crypto, interact) => {
    if (error) setError();
    setLoading(true);
    setChartObj(obj => ({
      ...obj,
      ShowAnalysisValue: false
    }))

    if (!ifInDateRange(crypto, cryptoDateRange, setDateOutOfRangeText, formatDateToDay(startDate), formatDateToDay(endDate))) {
      console.log('日期不合法')
      setError('Invalid Date.')
      setLoading(false);
      return;
    }

    let lastStartEndTime = genarateLastTimeGMT(formatDateToDay(startDate), formatDateToDay(endDate));
    console.log('lastStartEndTime in handleQuery:', lastStartEndTime)
    //TODO: set during query state

    setAnalysisText('');
    chart.current.clear() // 清空图表数据

    try {

      let { ShowAnalysisValue, chartData, upDownPoints, startDateValue, endDateValue } = await fetchData('bitcoin', lastStartEndTime, 'True');

      setLoading(false);
      createCustomChart(chartData, false, true, upDownPoints, ShowAnalysisValue, formatDateToDay(startDateValue), formatDateToDay(endDateValue));
      //createCustomChart(chartData, true, true, upDownPoints, ShowAnalysisValue, formatDateToDay(startDateValue), formatDateToDay(endDateValue));
    } catch (e) {
      console.log('error:', e);
      if (loading) setLoading(false);
    }
  }

  console.log('isMobile in chart:', isMobile)
  console.log('hasChartOptions in chart:', hasChartOptions)
  console.log('loading:', loading)

  return <div className='chart-wrap'>
    <div className='chart-content'>
      <div className={classNames('chart-container', { 'full-screen': isFullScreen })}>
        {loading && <div className='chart-mask'><Loader size={'xlarge'} color={'gray'} /></div>}
        {chartError && <div className='chart-mask error'>{chartError}</div>}
        <div className="y-axis-label">{t('Price ($)')}</div>
        <div ref={chartElement} className={classNames('chart', { 'full-screen': isFullScreen })}></div>
      </div>
      {isFullScreen && <div className='chart-analysis'>
        <div className='chart-analysis--title'>{t('Analysis Result')}</div>
        <div className='chart-analysis--content'>
          <Markup markup={analysisText} />
          {/* <Markdown remarkPlugins={[remarkGfm]} className="markdown" rehypePlugins={[rehypeRaw]}> */}
          {/* {analysisText}
          </Markdown>, */}
        </div>
      </div>}
    </div>
    <div className={classNames('chart-sidebar', { 'full-screen': isFullScreen })}>
      <ChartSelection coinOption={coinOption} setCoinOption={setCoinOption}
        startDate={startDate} setStartDate={setStartDate}
        endDate={endDate} setEndDate={setEndDate}
        checkboxMaxRef={checkboxMaxRef} isDateChanged={isDateChanged}
        handleCheckMax={handleCheckMax} loading={loading}
        handleGenerate={handleGenerate} handleClear={handleClear} isFullScreen={isFullScreen}
        handleQuery={handleQuery} error={error} setError={setError} />
    </div>
    {isMobile && hasChartOptions && <div className='chart-options-wrap'>
      <div className='chart-options'>
        <div className='chart-options__close-wrap'>
          <div className='chart-options__mobile-close' onClick={toggleOptions}></div>
        </div>
        <ChartSelection coinOption={coinOption} setCoinOption={setCoinOption}
          startDate={startDate} setStartDate={setStartDate}
          endDate={endDate} setEndDate={setEndDate}
          checkboxMaxRef={checkboxMaxRef} isDateChanged={isDateChanged}
          handleCheckMax={handleCheckMax} loading={loading}
          handleGenerate={handleGenerate} handleClear={handleClear} isFullScreen={isFullScreen}
          handleQuery={handleQuery} error={error} setError={setError} />
      </div>
    </div>}
  </div>
}

export default React.memo(Chart);


// light view chart demo
// useEffect(() => {
//   console.log(chart.current)
//   if (chart.current) return;
//   const chartOptions = {
//     height: isFullScreen ? 600 : 314,
//     layout: { textColor: '#8E92AB', background: { color: 'transparent' } },
//     grid: {
//       vertLines: { visible: false },
//       horzLines: { color: '#222838' },
//     },
//     leftPriceScale: { visible: true, ticksVisible: true, title: 'Price ($)' },
//     rightPriceScale: { visible: false }
//   };

//   chart.current = createChart(chartElement.current, chartOptions);
//   // Add a candlestick series to the chart
//   const candlestickSeries = chart.current.addCandlestickSeries();

//   // Apply the data to the candlestick series
//   candlestickSeries.setData(data);

//   // Add a click event listener to the chart
//   chart.current.subscribeClick((param) => {
//     console.log('param:', param)
//     if (param.time && param.seriesData) {
//       // Handle the click event and retrieve the data information
//       const clickedTime = param.time;
//       console.log('Clicked time:', clickedTime);

//       const clickedValue = param.seriesData.get(candlestickSeries);


//       console.log('Clicked value:', clickedValue);
//     }
//   });

//   chart.current.timeScale().fitContent();

//   // Clean up the chart when the component unmounts
//   // return () => {
//   //   console.log('clean up out')
//   //   if (chart.current) {
//   //     console.log('clean up')
//   //     chart.current.unsubscribeClick();
//   //     chart.current.remove();
//   //   }
//   // }
// }, [])