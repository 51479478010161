import React, { useState } from 'react';
import NavBarTop from '../components/NavBarTop';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { post } from '../service/fetch';
import { Button } from '../components/Button';
import { LuUserCircle2 } from "react-icons/lu";
import Loader from '../components/Loader';

function LoginForm({ onSubmit, loading, error }) {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();


  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form LoginForm'}
    >
      <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t('USERNAME')}</div>
        <input className='LoginPage__input' type="text" placeholder={t("Enter your username")}
          {...register("account", { required: true, maxLength: 83 })} />
      </label>
      <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t("PASSWORD")}</div>
        <input className='LoginPage__input' type="password" placeholder={t("Enter your password")}
          {...register("password", { required: true, maxLength: 100 })} />
      </label>

      {
        error &&
        <div className={'FormError'}>{error}</div>
      }
      <Button title={'Login'}
        role={'submit'}
        loading={loading}
        className='LoginPage__button'
      >{t("Sign in")}</Button>
    </form>
  );
}

function LoginPage() {
  const [loading, setLoading] = useState();
  const [aLoading, setALoading] = useState();
  const [error, setError] = useState(null);
  const [aError, setAError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();


  const onSubmit = async ({ account, password }) => {
    if (error) setError()
    setLoading(true);
    try {
      const response = await post({
        url: "/login",
        body: {
          account: account,
          password: password
        }
      })

      const { user_id, token, username, email, verify_token } = response;
      if (user_id === 'default') {
        console.log('Login failed');
        setError("Login failed, please check your username and password.");
      } else {
        localStorage.setItem('username', username);
        localStorage.setItem('token', token);
        localStorage.setItem('user-id', user_id);
        localStorage.setItem('email', email);
        localStorage.setItem('verify_token', verify_token);
        navigate("../dashboard");
      }
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false);
    }
  };

  const handleAnonymouseLogin = async () => {
    if (error) setError()
    setALoading(true);
    try {
      const response = await post({
        url: "/login",
        body: {
          account: 'ausr',
          password: 'apwd'
        }
      })

      const { user_id, token, username } = response;
      if (user_id === 'default') {
        console.log('Login failed');
        setAError("Login failed, please check your username and password.");
      } else {
        localStorage.setItem('username', username);
        localStorage.setItem('token', token);
        localStorage.setItem('user-id', user_id);
        navigate("../dashboard");
      }
    } catch (e) {
      setAError(e.message)
    } finally {
      setALoading(false);
    }
  };

  return <div className='LoginPage'>
    <NavBarTop />
    <div className='LoginPage__logo'></div>
    <div className='LoginPage__title'>{t('Log in')}</div>
    <div className='LoginPage__form'>
      <LoginForm onSubmit={onSubmit}
        loading={loading}
        error={error}
      />
    </div>
    <div className='LoginPage__note'>
      <div className='LoginPage__note--raw'>{t('Don’t have an account?')} <Link to='../signup'>{t('Sign up')}</Link></div>
      <div className='LoginPage__note--raw'>{t('Forgot Password?')} <Link to='../request-pwd-reset'>{t('Click here')}</Link></div>
    </div>
    <div className='LoginPage__links'>
      <div className='LoginPage__links--text'>- {t('OR')} -</div>
      {aLoading ? <div className='LoginPage__links--button'>
        <Loader size={'small'} />
      </div> :
        <div className='LoginPage__links--button' onClick={handleAnonymouseLogin}>
          <div className='LoginPage__links--logo guest'>
            <LuUserCircle2 />
          </div>
          {t('Continue as a Guest')}
        </div>}
      {
        aError &&
        <div className={'FormError'}>{aError}</div>
      }
      {/* <div className='LoginPage__links--button'>
        <div className='LoginPage__links--logo google'></div>
        {t('Continue with Google')}
      </div>
      <div className='LoginPage__links--button'>
        <div className='LoginPage__links--logo apple'></div>
        {t('Continue with Apple')}
      </div>
      <div className='LoginPage__links--button'>
        <div className='LoginPage__links--logo microsoft'></div>
        {t('Continue with Microsoft')}
      </div> */}
    </div>
  </div>
}

export default LoginPage;
