import React, { useState } from 'react';
import NavBarTop from '../components/NavBarTop';
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { post } from '../service/fetch';
import { Button } from '../components/Button';

function VerifyForm({ onSubmit, loading, error }) {
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();


  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form LoginForm'}
    >
      <label className='LoginPage__label'>
        <input className='LoginPage__input' type="text" placeholder={t("Enter your verification code")}
          {...register("code", { required: true, maxLength: 10 })} />
      </label>

      {
        error &&
        <div className={'FormError'}>{error}</div>
      }
      <Button title={'Verify'}
        role={'submit'}
        loading={loading}
        className='LoginPage__button'
      // onClick={onSubmit}
      >{t("Verify")}</Button>
    </form>
  );
}

function VerifyPage() {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  const encodedEmail = searchParams.get('e');
  const email = decodeURIComponent(encodedEmail);

  const onSubmit = async ({ code }) => {
    if (error) setError();
    setLoading(true);
    try {
      const response = await post({
        url: "/registerVerify",
        body: {
          code: code,
          token: token,
          email: email
        }
      })
      if (response.status === "success") {
        const { user_id, token, username, email, verify_token } = response.info;
        localStorage.setItem('username', username);
        localStorage.setItem('token', token);
        localStorage.setItem('user-id', user_id);
        localStorage.setItem('email', email);
        localStorage.setItem('verify_token', verify_token);
        navigate("../dashboard");
        setLoading(false);
        return;
      }
      if (response.status === 'fail') {
        setError(response.message)
      }
      console.log('response after registerVerify call:', response)
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false);
    }
  };

  return <div className='LoginPage'>
    <NavBarTop />
    <div className='LoginPage__logo'></div>
    <div className='LoginPage__title'>{t('Verify Code')}</div>
    <div className='LoginPage__form'>
      <VerifyForm onSubmit={onSubmit}
        loading={loading}
        error={error}
      />
    </div>
    <div className='LoginPage__note'>{t('Please check your email for the verification code.')}</div>
  </div>
}

export default React.memo(VerifyPage);
