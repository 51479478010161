import React, { useState, useCallback, useEffect, useRef } from 'react';
import NavBarSide from '../components/NavBarSide';
import { useTranslation } from 'react-i18next';
import Switch from '../components/Switch';
import Chart from '../components/Chart';
import { post, authHeader } from '../service/fetch';
import { io } from "socket.io-client";
import { DefaultCoinOption } from '../utils/constants';
import { getDate } from '../utils/helper';
import { Link, useNavigate, useParams } from "react-router-dom";
import classNames from 'classnames';

import "react-datepicker/dist/react-datepicker.css";
import Chat from '../components/Chat';
import config from '../config';

let DefaultCurChatId = getDate();
const ChatbotUrl = config.chatbotApi.host + ':' + config.chatbotApi.port
const Dashboard = () => {
  const [hasChart, setHasChart] = useState(window.innerWidth > 768);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [chatHistory, setChatHistory] = useState([{ 'response': "Hello! Ask me anything about Crypto." }]);
  const [curChat, setCurChat] = useState();
  const [curChatTitle, setCurChatTitle] = useState();
  const [isAsking, setIsAsking] = useState(false);
  const navigate = useNavigate();
  const { chatId } = useParams();

  const isAnonymous = localStorage.getItem('username') === 'ausr';
  const isUnverifiedUser = localStorage.getItem('verify_token') !== 'verified'
  const email = localStorage.getItem('email');
  const verifyToken = localStorage.getItem('verify_token');
  console.log('chatId:', chatId);
  console.log('isAnonymous:', isAnonymous);
  console.log('isUnverifiedUser:', isUnverifiedUser);

  // Chart related
  let chart = useRef(null),
    chartElement = useRef(null),
    checkboxMaxRef = useRef(null);
  let selectedPoints = useRef(new Set()), // 用于保存已选中的数据点
    TargetPoints = useRef([]),
    SpecialPointsValue = useRef([]),
    SpecialPointsLabel = useRef([]),
    xAxisData = useRef([]); //存储x轴时间
  const [chartLoading, setChartLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);

  const [coinOption, setCoinOption] = useState(DefaultCoinOption);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [preDates, setPreDates] = useState([0, 0])

  const [chartObj, setChartObj] = useState({
    upDownPoints: [],
    chartData: [],
    charOption: {}
  });
  const [selectedCrypto, setSelectedCrypto] = useState('bitcoin');
  const [cryptoDateRange, setCryptoDateRange] = useState([]);
  const [analysisText, setAnalysisText] = useState('');
  const [dateOutOfRangeText, setDateOutOfRangeText] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1248);
  const [hasSideBar, setHasSideBar] = useState(false);
  const [hasChartOptions, setHasChartOptions] = useState(false);

  const userId = localStorage.getItem('user-id');
  const socket = useRef();
  const inputRef = useRef();
  const apiRef = useRef();
  const searchModelRef = useRef();
  const shouldFetchChatListRef = useRef(false);
  const msgSentTime = useRef();
  let checkInterval;

  const checkIsMobile = () => {
    const width = window.innerWidth;
    setIsMobile(width <= 1248);
  };

  const { t } = useTranslation();

  useEffect(() => {
    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
      clearInterval(checkInterval);
    };
  }, []);

  useEffect(() => {
    const logoutListener = (event) => {
      if (event.key === 'logoutSignal') {
        navigate('/login');
      }
    };
    window.addEventListener('storage', logoutListener);
    return () => {
      window.removeEventListener('storage', logoutListener);
    };
  }, [navigate]);

  const fetchChatHistory = useCallback(async (chatId) => {
    console.log('fetchChatHistory called')
    try {
      const response = await post({
        url: "/chatHistory",
        body: {
          chatId: chatId
        },
        headers: authHeader()
      })
      // console.log(response)
      setChatHistory(response.history);
    } catch (e) {
      console.log('error:', e)
      if (e.code === 401) {
        navigate('/login');
      }
    } finally {
      setChatLoading(false);
    }
  }, [navigate])

  const handleSetCurChat = useCallback((chatId, title) => {
    console.log('chatId:', chatId)
    // console.log('curChat:', curChat)
    // if (chatId === curChat) return;
    if (isAsking) setIsAsking(false);
    setChatLoading(true);
    setChatHistory([]);
    setCurChat(chatId);
    fetchChatHistory(chatId);
    setCurChatTitle(title)
  }, [fetchChatHistory, isAsking])


  const fetchChatList = useCallback(async () => {
    console.log('fetchChatList called')
    try {
      const response = await post({
        url: "/chatHistoryList",
        headers: authHeader()
      })
      console.log(response);
      const { chat_list } = response;
      const chatList = chat_list.sort((a, b) => b.update_time - a.update_time);
      // const chatList = chat_date_list.map((date, i) => ({ id: date, title: chat_title_list[i], ts: Number(new Date(date)) })).sort((a, b) => b.ts - a.ts);
      console.log('chatList:', chatList)
      console.log('curChat:', curChat, 'DefaultCurChatId:', DefaultCurChatId)
      setChatList(chatList);
    } catch (e) {
      if (e.code === 401) {
        navigate('/login');
      }
    }
    // if (curChat !== undefined) return;
    // if (chatList.length) {
    //   const firstChatId = chatList[0].chat_id;
    //   if (firstChatId) handleSetCurChat(firstChatId, chatList[0].title || 'Title template');
    // }
  }, [curChat, navigate])

  useEffect(() => {
    return () => {
      console.log('Cleaning up WebSocket connection');
      if (socket.current)
        socket.current.disconnect();
    };
  }, [])

  useEffect(() => {
    console.log('mount')

    fetchChatList();
    console.log(socket.current);

    if (!socket.current || !socket.current.connected) {
      const token = localStorage.getItem('token')
      socket.current = io(ChatbotUrl, {
        reconnection: true,
        reconnectionAttempts: 3,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        extraHeaders: {
          Authorization: "Bearer " + token,
        },
      });
      console.log('setup socket');
      socket.current.on("connect", () => {
        console.log('socket.id:', socket.current.id);
      });
      socket.current.on("response", (obj) => {
        console.log('socket obj in response:', obj)
        console.log(obj.status === 'success', obj.status)
        if (obj.status === 'success') {
          msgSentTime.current = undefined;
          console.log('Reset is asking')
          setIsAsking(false);
          console.log('setting new ch')
          const newChat = { 'question': obj.question, 'response': obj.reply }
          setChatHistory(ch => {
            console.log('ch: ', ch);
            ch[ch.length - 1] = newChat;
            console.log('new ch:', ch)
            return ch.slice();
          });
          if (!curChat) setCurChat(obj.chatId)
          // shouldFetchChatListRef.current = true;
        }
      });
      socket.current.on('update_title', (obj) => {
        console.log('socket obj in update_title:', obj)
        fetchChatList();
      })
      // Event handler for the 'error' event
      socket.current.on('error', (error) => {
        console.error('Socket error:', error);
      });

      // Event handler for the 'reconnect' event
      socket.current.on('reconnect', (attemptNumber) => {
        console.log(`Socket reconnected after ${attemptNumber} attempts`);
      });

      checkInterval = setInterval(() => {
        if (msgSentTime.current === undefined) return;
        const curTime = +new Date();
        if (curTime - msgSentTime.current > 1000 * 70 || (socket.current && !socket.current.connected)) {
          setChatHistory(ch => {
            let newChat = ch[ch.length - 1];
            newChat.response = 'SYSTEM ERROR'
            ch[ch.length - 1] = newChat;
            return ch.slice();
          });
          msgSentTime.current = undefined;
        }
      }, 2000)
    }
  }, [fetchChatList, curChat])

  useEffect(() => {
    console.log('chatHistory.length:', chatHistory.length, shouldFetchChatListRef.current && chatHistory.length < 2)
    if (shouldFetchChatListRef.current && chatHistory.length < 2) {
      // Fetch the whole chatList here
      fetchChatList();
      shouldFetchChatListRef.current = false; // Reset the flag
    }
  }, [chatHistory, fetchChatList]);


  const handleSendMsg = () => {
    console.log('handleSendMsg')
    let msg = inputRef.current.value;
    // let api = apiRef.current.value;
    // console.log(api)
    // let isDetailedSearch = detailSeachRef.current.checked;
    // console.log('isDetailedSearch:', isDetailedSearch)
    let searchModel = searchModelRef.current.state.selectValue.map(option => option.value)[0];
    if (!msg) return;
    if (!socket || !socket.current || !socket.current.connected) {
      setChatHistory(ch => {
        if (!ch[0].question) return [{ 'question': msg, 'response': 'SYSTEM ERROR' }]
        else return ch.concat({ 'question': msg, 'response': 'SYSTEM ERROR' })
      })
      return;
    }
    console.log('socket.current:', socket.current)
    console.log('msg:', { chatId: curChat, data: msg, searchModel })
    // if(!curChat) setCurChat(getDate());
    socket.current.emit('message', { chatId: curChat ? curChat : "", data: msg, searchModel });
    msgSentTime.current = +new Date();
    inputRef.current.value = '';
    // console.log('chatHistory in handleSendMsg:', chatHistory)
    setIsAsking(true);
    setChatHistory(ch => {
      console.log('ch in handleSendMsg:', ch)
      if (!ch[0].question) return [{ 'question': msg, 'response': 'loading' }]
      else return ch.concat({ 'question': msg, 'response': 'loading' })
    })
  }

  const deleteChat = async (chatId) => {
    try {
      const response = await post({
        url: "/deleteChatLog",
        body: {
          chatId
        },
        headers: authHeader()
      })
      if (response.delte !== 'ok') return false;
      const newChatList = chatList.filter(o => {
        if (o.id === chatId) return false;
        return true;
      });
      setChatList(newChatList)
      handleNewChat();
      shouldFetchChatListRef.current = true;
      return true
    } catch (e) {
      if (e.code === 401) {
        navigate('/login');
      }
    }
  }

  const handleSwitch = useCallback(() => {
    if (chartLoading) return;
    if (isFullScreen) setIsFullScreen(false);
    setHasChart((prevHasChart) => !prevHasChart);
  }, [chartLoading, isFullScreen]);

  const toggleSideBar = useCallback(() => {
    if (!isMobile) return;
    setHasSideBar(v => !v)
  }, [isMobile])

  const toggleOptions = useCallback(() => {
    if (!isMobile) return;
    setHasChartOptions(v => !v)
  }, [isMobile])

  const handleFullScreen = useCallback(() => {
    console.log('handleFullScreen')
    setIsFullScreen(pre => !pre)
  }, [])

  const handleNewChat = useCallback(() => {
    console.log('handleNewChat called')
    // DefaultCurChatId = getDate()
    if (isAsking) setIsAsking(false);
    setCurChat("");
    setChatHistory([{ 'response': "Hello! Ask me anything about Crypto." }]);
    if (!hasChart) setHasChart(true);
    navigate('/')
  }, [setCurChat, setChatHistory, hasChart, isAsking, navigate])
  console.log('dashboard mount', hasChart)

  return isFullScreen ? <div className='dashboard'>
    <div className='dashboard__fs'>
      <div className='dashboard__fs-header'>
        <div className='dashboard__fs-header--section'>
          <div className='dashboard__cryptogpt-logo'></div>
        </div>
        <div className='dashboard__fs-header--section title'>
          {t('Cryptocurrency Price Analysis')}
        </div>
        <div className='dashboard__fs-header--section'>
          <div className='dashboard__screen-switch' onClick={handleFullScreen}>
            <div className='dashboard__full-screen-text'>{t('EXIT FULL SCREEN')}</div>
            <div className='dashboard__exit-full-screen-icon'></div>
          </div>
          <div className='dashboard__switch-wrap'>
            {t('Chart')}
            <Switch className={`dashboard__switch ${chartLoading ? 'disabled' : ''}`}
              onChange={handleSwitch} checked={hasChart} />
          </div>
        </div>
      </div>
      <div className='dashboard__fs-body'>
        <Chart isFullScreen={true} handleFullScreen={handleFullScreen} coinOption={coinOption} setCoinOption={setCoinOption}
          startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} chartObj={chartObj}
          setChartObj={setChartObj} selectedCrypto={selectedCrypto} setSelectedCrypto={setSelectedCrypto} cryptoDateRange={cryptoDateRange}
          setCryptoDateRange={setCryptoDateRange} analysisText={analysisText} setAnalysisText={setAnalysisText}
          dateOutOfRangeText={dateOutOfRangeText} setDateOutOfRangeText={setDateOutOfRangeText} chart={chart} chartElement={chartElement}
          checkboxMaxRef={checkboxMaxRef} selectedPoints={selectedPoints} setChatHistory={setChatHistory}
          TargetPoints={TargetPoints} SpecialPointsValue={SpecialPointsValue} SpecialPointsLabel={SpecialPointsLabel}
          xAxisData={xAxisData} preDates={preDates} setPreDates={setPreDates} socket={socket} curChat={curChat} setIsAsking={setIsAsking}
          shouldFetchChatListRef={shouldFetchChatListRef} loading={chartLoading} setLoading={setChartLoading} hasChartOptions={hasChartOptions}
          toggleOptions={toggleOptions} isMobile={isMobile} />
      </div>
    </div>
  </div> : <div className={`dashboard ${isMobile ? 'isMobile' : ''}`}>
    {isMobile ? (hasSideBar && <div className='dashboard__nav-bar isMobile' >
      <NavBarSide chatList={chatList} handleSetCurChat={handleSetCurChat} curChat={curChat}
        deleteChat={deleteChat} handleNewChat={handleNewChat} curChatTitle={curChatTitle}
        chatHistory={chatHistory} setHasChart={setHasChart} hasChart={hasChart} fetchChatList={fetchChatList}
        toggleSideBar={toggleSideBar} />
    </div >) : <div className='dashboard__nav-bar' >
      <NavBarSide chatList={chatList} handleSetCurChat={handleSetCurChat} curChat={curChat}
        deleteChat={deleteChat} handleNewChat={handleNewChat} curChatTitle={curChatTitle}
        chatHistory={chatHistory} setHasChart={setHasChart} hasChart={hasChart} fetchChatList={fetchChatList}
        toggleSideBar={toggleSideBar} />
    </div >}
    <div className='dashboard__content'>
      {isAnonymous && <div className="dashboard__banner">
        <div className="dashboard__banner--content">
          <Link to='../signup'>
            {t('Your account is unregistered. Sign up to remove message limits, save and share chats, & more!')}
          </Link>
        </div>
      </div>}
      {isUnverifiedUser && !isAnonymous && <div className="dashboard__banner">
        <div className="dashboard__banner--content">
          <Link to={`../verify?t=${verifyToken}&e=${encodeURIComponent(email)}`}>
            {t('Your account is not verified. Verify to remove message limits, save and share chats, & more!')}
          </Link>
        </div>
      </div>}
      {isMobile ? <div className='dashboard__mobile-header'>
        <div className='dashboard__expand-wrap' onClick={toggleSideBar}>
          <div className='dashboard__expand-switch'></div>
        </div>
        <div className='dashboard__mobile-logo-wrap'>
          <div className='dashboard__mobile-logo'></div>
        </div>
        <div className={classNames('dashboard__switch-wrap', { 'hasChart': hasChart })}>
          {t("Chart")}
          <Switch className={classNames('dashboard__switch', { 'disabled': chartLoading })} onChange={handleSwitch} checked={hasChart} />
        </div>
        {hasChart && <div className='dashboard__options-switch-wrap'>
          <div className='dashboard__options-switch' onClick={toggleOptions}></div>
        </div>}
      </div> :
        <div className={classNames('dashboard__switch-wrap', { 'isAnonymous': isAnonymous || isUnverifiedUser })}>
          {t("Chart")}
          <Switch className={classNames('dashboard__switch', { 'disabled': chartLoading })} onChange={handleSwitch} checked={hasChart} />
        </div>}
      {hasChart && <div className='dashboard__header'>
        <div className='dashboard__header-topbar'>
          <div className='dashboard__header-topbar--section'></div>
          <div className='dashboard__header-topbar--section title'>
            {t('Cryptocurrency Price Analysis')}
          </div>
          {!isMobile && <div className='dashboard__header-topbar--section' onClick={handleFullScreen}>
            <div className='dashboard__screen-switch'>
              <div className='dashboard__full-screen-text'>{t('FULL SCREEN')}</div>
              <div className='dashboard__full-screen-icon'></div>
            </div>
          </div>}
        </div>
        <div className='dashboard__header-content'>
          <Chart isFullScreen={false} handleFullScreen={handleFullScreen} coinOption={coinOption} setCoinOption={setCoinOption}
            startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} chartObj={chartObj}
            setChartObj={setChartObj} selectedCrypto={selectedCrypto} setSelectedCrypto={setSelectedCrypto} cryptoDateRange={cryptoDateRange}
            setCryptoDateRange={setCryptoDateRange} analysisText={analysisText} setAnalysisText={setAnalysisText}
            dateOutOfRangeText={dateOutOfRangeText} setDateOutOfRangeText={setDateOutOfRangeText} chart={chart} chartElement={chartElement}
            checkboxMaxRef={checkboxMaxRef} selectedPoints={selectedPoints} setChatHistory={setChatHistory}
            TargetPoints={TargetPoints} SpecialPointsValue={SpecialPointsValue} SpecialPointsLabel={SpecialPointsLabel}
            xAxisData={xAxisData} preDates={preDates} setPreDates={setPreDates} socket={socket} curChat={curChat} setIsAsking={setIsAsking}
            shouldFetchChatListRef={shouldFetchChatListRef} loading={chartLoading} setLoading={setChartLoading} hasChartOptions={hasChartOptions}
            toggleOptions={toggleOptions} isMobile={isMobile} />
        </div>
      </div>}
      <Chat chatHistory={chatHistory} hasChart={hasChart} handleSendMsg={handleSendMsg} inputRef={inputRef} isAsking={isAsking} apiRef={apiRef}
        chatLoading={chatLoading} searchModelRef={searchModelRef} />
    </div>
  </div>
}

export default React.memo(Dashboard);
