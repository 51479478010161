import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Button } from '../components/Button';
import NavBarTop from '../components/NavBarTop';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { post } from '../service/fetch';


function ResetPasswordForm({ onSubmit, loading, error, loaded }) {
  const { t } = useTranslation();
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange"
  });

  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form'}
    >
      <label className='LoginPage__label'>
        <div className='LoginPage__label--text'>{t("NEW PASSWORD")}</div>
        <input className='LoginPage__input' type="password" placeholder={t("Enter your new password")}
          {...register("password", { required: true, maxLength: 100 })} />
        <input className='LoginPage__input' type="password" placeholder={t("Re-enter your new password")}
          {...register("passwordRe", { required: true, maxLength: 100 })} />
      </label>
      <div className={'FormError'}>{error}</div>

      {loaded ? <div className='FormSuccess'>
        Your password has been reset successfully, redirecting to login page...
      </div> : <Button title={'Reset Password'}
        role={'submit'}
        loading={loading}
        className='LoginPage__button'
        disabled={!formState.isValid}
      />}
    </form>
  );
}

function ResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  const encodedEmail = searchParams.get('e');
  const email = decodeURIComponent(encodedEmail);


  const onSubmit = async (data) => {
    if (error) setError();
    const { password, passwordRe } = data;
    if (password !== passwordRe) {
      setError('Miss matched password.')
      return;
    }
    if (password.length < 8) {
      setError('Password is too short. It must be at least 8 characters long.')
      return;
    }
    setLoading(true);
    try {
      const response = await post({
        url: "/resetPassword",
        body: {
          account: email,
          password: password,
          verify_code: token
        }
      })
      if (response.status === 'fail') {
        if (response.message)
          setError(response.message);
        else
          setError("Something went wrong, please try again later.")
        return;
      }

      console.log('response in request reset pwd:', response)
      setLoaded(true);
      setTimeout(() => navigate('../'), 1500)
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return <div className={'LoginPage'}>
    <NavBarTop />
    <div className='LoginPage__logo'></div>
    <div className='LoginPage__title'>{t('Reset Your Password')}</div>
    <div className='LoginPage__form'>
      <ResetPasswordForm onSubmit={onSubmit}
        loading={loading}
        error={error}
        loaded={loaded}
      />
    </div>
  </div>
}

export default ResetPasswordPage;