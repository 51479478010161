import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

const ChatLoader = (props) => {
  const { len = 6, isDetailedSearch } = props;
  const [dots, setDots] = useState('.');
  const [textIndex, setTextIndex] = useState(0);
  const { t } = useTranslation();
  const texts = isDetailedSearch ? ["", t("Searching in greater depth"), t("Analyzing relevant articles found")] : [""];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((dots) => (dots.length === len ? '.' : dots + '.'));
    }, 500);

    const textRotationId = setInterval(() => {
      setTextIndex((index) => (index === texts.length - 1 ? 0 : index + 1));
    }, 5000);

    return () => {
      clearInterval(intervalId);
      clearInterval(textRotationId);
    };
  }, []);

  return (
    <>
      {`${texts[textIndex]}${dots}`}
    </>
  );
};

export default ChatLoader;