import Loader from "./Loader";
// import {FaArrowLeft} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const classNames = require('classnames');

export const Button = ({ title, color = 'green', size = 'medium', href, target, onClick, disabled, loading, className, style, role, icon }) => {
  const navigate = useNavigate();

  const onClickLocal = e => {
    if (loading || disabled)
      return;

    if (href) {
      if (target === "_blank") {
        let link = document.createElement("a");
        link.href = href;
        link.target = '_blank'
        link.click();
      } else if (href.indexOf("http") !== -1) {
        window.location = href;
      } else {
        navigate(href);
      }
    }

    onClick?.(e);
  }

  return (
    <button className={classNames('Button', `Button--${color}`, `Button--${size}`, className)}
      style={style}
      onClick={onClickLocal}
      disabled={disabled}
      href={href}
      target={target}
      type={role}
    >
      <div className="button-icon">
        {icon}
      </div>

      <span style={{ display: (loading ? 'none' : 'block') }}>{title}</span>
      {
        loading &&
        <Loader size={'small'} />
      }
    </button>
  )
}

export const BackButton = ({ title, href, target, onClick, disabled, className, style }) => {
  const navigate = useNavigate();

  const onClickLocal = e => {
    if (disabled)
      return;

    if (href) {
      if (target === "_blank") {
        let link = document.createElement("a");
        link.href = href;
        link.target = '_blank'
        link.click();
      } else if (href.indexOf("http") !== -1) {
        window.location = href;
      } else {
        navigate(href);
      }
    }

    onClick?.(e);
  }

  return (
    <button className={classNames('BackButton', className)}
      style={style}
      onClick={onClickLocal}
      disabled={disabled}
      href={href}
      target={target}
    >
      {/* <FaArrowLeft/> */}
      <span>{title}</span>
    </button>
  )
}

export const LargeButton = props => <Button {...props} size="l"></Button>;
export const SmallButton = props => <Button {...props} size="s"></Button>;
export const XSmallButton = props => <Button {...props} size="xs"></Button>;
export const LightButton = props => <Button {...props} color="sub"></Button>;
