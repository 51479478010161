
import React, {useState, useCallback, useEffect, useRef, useMemo, Fragment} from 'react';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ChatLoader from './ChatLoader';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Markup } from 'react-render-markup';
import { ErrorMsgs, ErrorMsgsMap } from '../utils/helper';
import Loader from './Loader';
import Select from 'react-select';
import { ModelOptions, SuggestionOptions } from '../utils/constants';

const Chat = (props) => {
  const { chatHistory, hasChart, handleSendCurrentInput, handleSendMessage, inputRef, isAsking, apiRef,
    chatLoading, searchModelRef, chatSuggestionOptions, selectedDisplayTab } = props;
  const [input, setInput] = useState("");
  const userName = localStorage.getItem('username') || "User";
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);
  const [isDetailedSearch, setIsDetailedSearch] = useState(true);
  const chatHistoryRef = useRef();
  const { t } = useTranslation();

  const hideSuggestions = useMemo(() => chatHistory.length === 0
      || (chatHistory.length === 1 && chatHistory[0].question !== undefined), [chatHistory]);

  let isScrolled = false;

  const handleTextareaFocus = () => {
    if (!isTextareaFocused) setIsTextareaFocused(true);
  };

  const handleTextareaBlur = () => {
    if (isTextareaFocused) setIsTextareaFocused(false);
  };

  const handleScroll = e => {
    // console.log('handleScroll')
    let element = e.target;
    // console.log('element:', chatHistoryRef.current.scrollTop, chatHistoryRef.current.scrollHeight)
    if (element.scrollHeight - element.scrollTop > element.clientHeight) isScrolled = true;
    else isScrolled = false;
  }

  const autoScroll = useCallback(() => {
    // console.log('auto scroll', isScrolled, chatHistoryRef.current && chatHistoryRef.current.scrollTop,
    //   chatHistoryRef.current && chatHistoryRef.current.scrollHeight);
    if (!isScrolled && chatHistoryRef.current) {
      // console.log('auto scroll')
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isScrolled])

  const handleChange = (e) => {
    setInput(e.target.value);
    const textareaLineHeight = 16;
    e.target.style.height = '16px';
    e.target.style.height = `${Math.max(e.target.scrollHeight, textareaLineHeight)}px`;
  };

  const handleSendTextDirectly = (message) => {
    if (isAsking) return;
    handleSendMessage(message);
    resizeTextarea();
  }

  const handleSentAction = () => {
    if (isAsking) return;
    handleSendCurrentInput();
    resizeTextarea();
    setInput("");
  }
  const resizeTextarea = useCallback(() => {
    const textareaLineHeight = 16;
    inputRef.current.style.height = '16px';
    inputRef.current.style.height = `${Math.max(inputRef.current.scrollHeight, textareaLineHeight)}px`;
  }, [inputRef])

  useEffect(() => {
    if (chatHistory.length === 1 && !chatHistory[0].question && !hasChart) return;
    console.log('before autoscroll');
    autoScroll();
  }, [chatHistory, autoScroll, hasChart])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSentAction();
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      resizeTextarea();
    }
  }, [inputRef, resizeTextarea])

  const handleSuggestion = (text) => {
    handleSendTextDirectly(text);
  }

  return <>
    <div className={classNames('dashboard__chat', {
      'mid': chatHistory.length === 1 && !chatHistory[0].question && !hasChart,
      'loading': chatLoading
    })}>
      {chatLoading ? <Loader size='large'/> :
        <div className='dashboard__chat-history--wrap standard-scroll' ref={chatHistoryRef} onScroll={handleScroll}>
          {chatHistory.map((chat, i) => {
            return <Fragment key={i}>
              {chat.question && <div className='dashboard__chat-row question'>
                <div className='dashboard__chat-sender'>
                  {userName.charAt(0).toUpperCase()}
                </div>
                <div className='dashboard__chat-content'>
                  <Markdown remarkPlugins={[remarkGfm]} className="markdown" rehypePlugins={[rehypeRaw]}>
                    {chat.question}
                  </Markdown>
                </div>
              </div>}
              {chat.response && <div className='dashboard__chat-row'>
                <div className='dashboard__chat-sender system'>
                </div>
                <div className={`dashboard__chat-content ${ErrorMsgs.has(chat.response) ? 'error' : ''}`}>
                  {chat.response === 'loading' ? <ChatLoader
                          isDetailedSearch={searchModelRef.current?.state?.selectValue?.map(option => option.value)[0] === 'deep_search'}/> :
                      ErrorMsgs.has(chat.response) ?
                          <p>{t(ErrorMsgsMap[chat.response])}</p>
                          : <Markup markup={chat.response}/>
                  }</div>
              </div>}
            </Fragment>
          })}
        </div>}
    </div>
    <div className='dashboard__chat-footer'>
      <div className='dashboard__chat-footer--wrap'>
        <div className={`dashboard__label-container`}>
          {
            selectedDisplayTab != "news" || hideSuggestions || !chatSuggestionOptions ? null :
              <div className={"dashboard__chat-suggestion"}>
                <div className={"dashboard__chat-suggestion-options"}>
                  <div className={"dashboard__chat-suggestion-help"}>Trending:</div>
                  {Object.entries(chatSuggestionOptions).map(([label, text], index) =>
                      <span key={index} onClick={() => handleSuggestion(text)}>{label}</span>)}
                </div>
              </div>
          }
          <div className={`dashboard__label-options`}>
            {
              selectedDisplayTab === "news" ? <div className={'dashboard__modal-fixed-label'}>
                Rag News
              </div> : <div className='dashboard__modal-selector'>
                <Select
                    className="model-select-container"
                    classNamePrefix="model-select"
                    menuPlacement="top"
                    defaultValue={ModelOptions[0]}
                    options={ModelOptions}
                    ref={searchModelRef}
                    isDisabled={isAsking}/>
              </div>
            }
          </div>
          <a target='_blank' rel="noreferrer"
             href={'https://docs.google.com/forms/d/e/1FAIpQLSfPN2614IHsvDu6I_RmEGglZvbnld2XLEF2Yv-Kk8URjD0msA/viewform'}>
            <div className={classNames('dashboard__chat-footer--feedback')}>{t('Feedback')}</div>
          </a>
        </div>
        <div className='dashboard__chat-input'>
          {/* <div className='dashboard__chat-mask'></div> */}
          <textarea type='text'
                    value={input}
                    className='dashboard__chat-textarea standard-scrollbar'
                    onFocus={handleTextareaFocus}
                    onBlur={handleTextareaBlur}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('Ask a question') + "..."}
                    ref={inputRef}
                    disabled={isAsking}/>
          <div className={classNames('dashboard__send-button', {'focused': isTextareaFocused, 'disabled': isAsking})}
               onClick={handleSentAction}>
          </div>
        </div>
        <div className='dashboard__chat-note'>
          {t('This is not financial advice. DYOR. AlphaCrypto may produce inaccurate information about crypto, projects, or facts.')}
        </div>
      </div>
    </div>
  </>
}

export default React.memo(Chat);