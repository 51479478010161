import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Button } from '../components/Button';
import NavBarTop from '../components/NavBarTop';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { post } from '../service/fetch';


function RequestResetPasswordForm({ onSubmit, loading, error, loaded }) {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange"
  });

  const onSubmitLocal = (e) => {
    e.preventDefault();

    handleSubmit(onSubmit)();
  }

  return (
    <form onSubmit={onSubmitLocal}
      className={'Form'}
    >
      <input className='LoginPage__input' type="text" placeholder="Email" {...register("email", { required: true, maxLength: 83 })} />

      <div className={'FormError'}>{error}</div>

      {loaded ? <div className='FormSuccess'>
        An email has been sent to you with instructions to reset your password.
      </div> : <Button title={'Reset Password'}
        role={'submit'}
        loading={loading}
        className='LoginPage__button'
        disabled={!formState.isValid}
      />}
    </form>
  );
}

function RequestResetPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async ({ email }) => {
    if (error) setError();
    setLoading(true);
    try {
      // await dispatch(User.actions.requestResetPassword(email));
      //sendVerifyCode
      const response = await post({
        url: "/sendVerifyCode",
        body: {
          account: email,
        }
      })
      setLoading(false);
      console.log('response in request reset pwd:', response)

      if (response.status === 'fail') {
        if (response.message)
          setError(response.message);
        else
          setError("Something went wrong, please try again later.")
        return;
      }

      setLoaded(true);
      setTimeout(() => navigate("../"), 1500);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return <div className={'LoginPage'}>
    <NavBarTop />
    <div className='LoginPage__logo'></div>
    <div className='LoginPage__title'>{t('Reset Your Password')}</div>
    <div className='LoginPage__form'>
      <RequestResetPasswordForm onSubmit={onSubmit}
        loading={loading}
        error={error}
        loaded={loaded}
      />
    </div>
  </div>
}

export default RequestResetPasswordPage;