import { useEffect, useState } from 'react';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';

const DeleteChatModal = props => {
  const { onClose, chatTitle, curChat, handleDeleteChat } = props;
  const { t } = useTranslation();

  return <Modal className="delete-chat-modal" onClose={onClose}>
    <div className="modal-title">{t('Delete chat') + t('?')}</div>
    <div className="modal-hr"></div>
    <div className="modal">
      <div className='delete-chat-modal__content'>
        <div className='delete-chat-modal__body'>
          {t("This will delete") + ' '}<span>{chatTitle}</span>{t(".")}
        </div>
        <div className='delete-chat-modal__footer'>
          <Button className={'delete-chat-modal__button'} title={t('Cancel')} onClick={onClose} />
          <Button className={'delete-chat-modal__button delete'} title={t('Delete')} onClick={() => handleDeleteChat(curChat)} />
        </div>
      </div>
    </div>
  </Modal>
}


export default DeleteChatModal;