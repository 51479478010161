import i18n from './i18n';

export function getTick(maxPrice, minPrice) {
  let tick;
  if (maxPrice - minPrice > 0.1) {
    tick = (maxPrice - minPrice) / 4;
  } else {
    tick = (maxPrice - minPrice) / 2;
  }
  if (tick > 1) {
    let cnt = 0;
    while (tick / 10 > 1) {
      tick = tick / 10;
      cnt++;
    }
    tick = Math.round(tick);
    for (let i = 0; i < cnt; i++) {
      tick = tick * 10;
    }
  } else {
    let cnt = 0;
    while (tick < 1) {
      // console.log("now tick: "+tick);
      tick = tick * 10;
      cnt++;
    }
    tick = Math.round(tick)
    for (let i = 0; i < cnt; i++) {
      tick = tick / 10;
    }
  }
  return tick;
}

// const verifyDate = (value, type) => {

//   const regExp = /^\d{8}$/; // match 8 digits exactly
//   if (!regExp.test(value)) {
//     console.error(`无效的日期格式: ${type === 'start' ? '开始日期' : '结束日期'}`);

//     if (type === 'start') {
//       if (language_type.value == 'ch') {
//         illegalStartDateText.value = '开始日期格式不合法';
//       }
//       else {
//         illegalStartDateText.value = 'The start date format is incorrect.';
//       }

//     } else {
//       if (language_type.value == 'ch') {
//         illegalEndDateText.value = '结束日期格式不合法';
//       }
//       else {
//         illegalEndDateText.value = 'The end date format is incorrect.';
//       }

//     }
//     return;
//   }
//   const year = value.substr(0, 4);
//   const month = value.substr(4, 2) - 1; // 0 indexed
//   const day = value.substr(6, 2);
//   const date = new Date(year, month, day);
//   if (date.getFullYear() != year || date.getMonth() != month || date.getDate() != day) {
//     console.error(`无效的日期: ${type === 'start' ? '开始日期' : '结束日期'}`);
//     if (type === 'start') {
//       if (language_type.value == 'ch') {
//         illegalStartDateText.value = '开始日期格式不合法';
//       }
//       else {
//         illegalStartDateText.value = 'The start date format is incorrect.';
//       }
//     } else {
//       if (language_type.value == 'ch') {
//         illegalEndDateText.value = '结束日期格式不合法';
//       }
//       else {
//         illegalEndDateText.value = 'The end date format is incorrect.';
//       }
//     }
//     return;
//   } else {
//     if (type === 'start') {
//       console.log(startDate.value)
//       startDate.value = value;
//     } else {
//       console.log(endDate.value)
//       endDate.value = value;
//     }
//   }

//   // 没问题
//   if (type === 'start') {
//     illegalStartDateText.value = "";
//   } else {
//     illegalEndDateText.value = "";
//   }
// }

function getBottom(min) {
  // 只保留min的最高位

  if (min > 1) {
    let cnt = 0;
    while (min / 10 > 1) {
      min = min / 10;
      cnt++;
    }
    min = Math.floor(min);
    for (let i = 0; i < cnt; i++) {
      min = min * 10;
    }
  }
  else if (min > 0 && min < 1) {
    let cnt = 0;
    while (min < 1) {
      min = min * 10;
      cnt++;
    }
    min = Math.floor(min)
    for (let i = 0; i < cnt; i++) {
      min = min / 10;
    }
  }
  return min;
}

export function getYAxis(useLogScale, maxPrice, minPrice, axisName) {

  let tick = getTick(maxPrice, minPrice);
  // console.log("maxPrice: "+maxPrice+" minPrice: "+minPrice);
  // console.log("tick: "+tick);

  // 确定小数位数
  let dotCnt = 0;
  let dotTick = tick;
  while (dotTick < 1) {
    dotCnt++;
    dotTick = dotTick * 10;
  }
  // console.log("tick: "+tick+" dotCnt: "+dotCnt);


  let bottom = Math.floor(minPrice / tick) * tick;  // 坐标轴上的最低刻度
  if (bottom === 0) {
    // 一个很特殊的情况, 当bottom为0的时候
    bottom = getBottom(minPrice);
  }
  let spNum = Math.ceil((maxPrice - Math.floor(minPrice / tick) * tick) / tick);   // 分成多少份
  let top = spNum * tick + bottom;  // 坐标轴上最高刻度
  // console.log("spNum: "+spNum);

  // 在轴上需要被标出的  线性刻度
  let outLabel = [];
  outLabel.push(bottom);
  for (let i = 1; i < spNum; i++) {
    outLabel.push(bottom + i * tick);
  }
  outLabel.push(top);

  // 计算线性刻度的位置
  let linearLocation = []
  let linearLen = top - bottom;
  linearLocation.push(1);
  for (let i = 1; i < spNum; i++) {
    let loc = Math.round((outLabel[i] - bottom) / linearLen * 100);
    linearLocation.push(loc);
  }
  linearLocation.push(99);

  // 计算log的位置
  let top_log = Math.log(top);
  let bottom_log = Math.log(bottom);
  let len = top_log - bottom_log;
  let logLocation = [];
  logLocation.push(1);
  for (let i = 1; i < spNum; i++) {
    let log_i = Math.log(outLabel[i]);
    let label_i = Math.round((log_i - bottom_log) / len * 100);
    //console.log("log_i "+log_i+ "bottom_log "+bottom_log+" label_i "+label_i);
    logLocation.push(label_i);
  }

  logLocation.push(99);

  let yAxiOption = [
    {
      type: useLogScale ? 'log' : 'value',
      position: 'left',
      logBase: 10, // 对数轴的底数, 默认为10
      min: bottom,
      max: top,
      axisLine: {
        show: false // 隐藏
      },
      axisTick: {
        show: false // 隐藏
      },
      axisLabel: {
        show: false //
      },
      splitLine: {
        show: false
      },
    },
    {
      type: 'value',
      position: 'left',
      min: 0,
      max: 100,
      //interval: (Math.floor( maxPrice/ 500) + 1) - Math.floor(minPrice/ 500) <=10 ?500 :1000,
      splitNumber: 100,//Math.min((Math.floor( maxPrice/ 500) + 1) - Math.floor(minPrice/ 500) , 10) ,
      axisLabel: {
        show: true, // 显示标签
        fontSize: 12,
        formatter: function (value, index) {
          if (useLogScale) {
            if (logLocation.includes(value)) {
              // 显示的数值
              // 修改显示的精度问题
              //return outLabel[logLocation.indexOf(value)].toPrecision(3).toLocaleString();
              return outLabel[logLocation.indexOf(value)].toFixed(dotCnt).toLocaleString();
            }
          } else if (!useLogScale) {
            if (linearLocation.includes(value)) {
              //return outLabel[linearLocation.indexOf(value)].toPrecision(3).toLocaleString();
              return outLabel[linearLocation.indexOf(value)].toFixed(dotCnt).toLocaleString();
            }
          }
          return '';
        },
        color: '#8E92AB',
      },
      // 刻度线显示有问题
      splitLine: {
        show: false
      },
    },
  ];

  return yAxiOption;
}


function getMarkLine(data, useLogScale) {
  let minPrice = Math.min(...data.map((item) => item.price));
  let maxPrice = Math.max(...data.map((item) => item.price));
  let tick = getTick(maxPrice, minPrice); // 分成多少份
  let bottom = Math.floor(minPrice / tick) * tick;  // 坐标轴上的最低刻度
  if (bottom === 0) {
    console.log("原bottom为0了")
    // 一个很特殊的情况, 当bottom为0的时候
    bottom = getBottom(minPrice);
  }
  let spNum = Math.ceil((maxPrice - Math.floor(minPrice / tick) * tick) / tick);   // 分成多少份
  let top = spNum * tick + bottom;  // 坐标轴上最高刻度

  // 在轴上需要被标出的线性刻度
  let outLabel = [];
  outLabel.push(bottom);
  for (let i = 1; i < spNum; i++) {
    outLabel.push(bottom + i * tick);
  }
  outLabel.push(top);

  // 计算线性刻度的位置
  let linearLocation = []
  let linearLen = top - bottom;
  //linearLocation.push(1);
  for (let i = 1; i < spNum; i++) {
    let loc = Math.round((outLabel[i] - bottom) / linearLen * 100);
    linearLocation.push(loc);
  }
  linearLocation.push(99);

  // 计算log的位置
  let top_log = Math.log(top);
  let bottom_log = Math.log(bottom);
  let len = top_log - bottom_log;
  let logLocation = [];
  // logLocation.push(1);
  for (let i = 1; i < spNum; i++) {
    let log_i = Math.log(outLabel[i]);
    let label_i = Math.round((log_i - bottom_log) / len * 100);
    //console.log("log_i "+log_i+ "bottom_log "+bottom_log+" label_i "+label_i);
    logLocation.push(label_i);
  }
  logLocation.push(99);

  let res;
  res = {
    silent: true,  // markLine 无响应
    symbol: 'none', // 去掉箭头
    symbolSize: 0, // 去掉标记点
    // animation: false, // 关闭动画效果
    label: {
      show: false // 去掉文字标签
    },
    emphasis: {
      lineStyle: {
        color: '#000000',
        type: 'dashed',
        width: 1 // 设置鼠标悬停时的线条宽度为1
      }
    },
  }

  let datas = [];
  if (useLogScale) {
    logLocation.forEach(function (item, index, array) {
      datas.push({ yAxis: item, lineStyle: { color: '#222838', type: 'dashed', width: 1 } });
    });
  } else {
    linearLocation.forEach(function (item, index, array) {
      datas.push({ yAxis: item, lineStyle: { color: '#222838', type: 'dashed', width: 1 } });
    });
  }

  res.data = datas;
  //console.log(res.data)
  return res;
}

// function getCpiDescribe(axisTime) {
//   let cpi_index = cpi_datas.findIndex((item) => item.time === axisTime)
//   let outStr = ""
//   if (cpi_index >= 0) {
//     outStr = `\nCPI为`;
//     outStr = outStr + cpi_datas[cpi_index].cpi_new;
//     if (!isNaN(cpi_datas[cpi_index].cpi_yoy)) {
//       if (+cpi_datas[cpi_index].cpi_yoy > 0) {
//         outStr = outStr + ", 同比上涨";
//         outStr = outStr + (cpi_datas[cpi_index].cpi_yoy * 100).toFixed(6).toLocaleString() + '%';
//       }
//       if (+cpi_datas[cpi_index].cpi_yoy < 0) {
//         outStr = outStr + ", 同比下跌";
//         outStr = outStr + (-cpi_datas[cpi_index].cpi_yoy * 100).toFixed(6).toLocaleString() + '%';
//       }
//     }
//     if (!isNaN(cpi_datas[cpi_index].cpi_qoq)) {
//       if (+cpi_datas[cpi_index].cpi_qoq > 0) {
//         outStr = outStr + ", 月环比上涨";
//         outStr = outStr + (cpi_datas[cpi_index].cpi_qoq * 100).toFixed(6).toLocaleString() + '%';
//       }
//       if (+cpi_datas[cpi_index].cpi_qoq < 0) {
//         outStr = outStr + ", 月环比下降";
//         outStr = outStr + (-cpi_datas[cpi_index].cpi_qoq * 100).toFixed(6).toLocaleString() + '%';
//       }
//     }

//     if (cpi_datas[cpi_index].cpi_new - cpi_datas[cpi_index].cpi_pred > 0) {
//       outStr = outStr + "。\n其数值高于";
//     } else {
//       outStr = outStr + "。\n其数值低于";
//     }
//     outStr = outStr + "市场预期" + (Math.abs(cpi_datas[cpi_index].cpi_new - cpi_datas[cpi_index].cpi_pred) / cpi_datas[cpi_index].cpi_pred * 100).toFixed(4).toLocaleString() + '%';

//     if (!isNaN(cpi_datas[cpi_index].cpi_yoy_compare) && !isNaN(cpi_datas[cpi_index].cpi_qoq_compare)) {
//       outStr = outStr + "。\n相较于前一个月"
//       if (+cpi_datas[cpi_index].cpi_yoy_compare > 0 && +cpi_datas[cpi_index].cpi_qoq_compare > 0) {
//         outStr = outStr + ", CPI的同比和环比均呈上升趋势"
//       }
//       else if (+cpi_datas[cpi_index].cpi_yoy_compare < 0 && +cpi_datas[cpi_index].cpi_qoq_compare < 0) {
//         outStr = outStr + ", CPI的同比和环比均呈下降趋势"
//       } else {
//         if (+cpi_datas[cpi_index].cpi_yoy_compare > 0) {
//           outStr = outStr + ", CPI的同比呈上升趋势";
//         }
//         else if (+cpi_datas[cpi_index].cpi_yoy_compare < 0) {
//           outStr = outStr + ", CPI的同比呈下降趋势";
//         }
//         if (+cpi_datas[cpi_index].cpi_qoq_compare > 0) {
//           outStr = outStr + ", 环比呈上升趋势";
//         }
//         else if (+cpi_datas[cpi_index].cpi_qoq_compare < 0) {
//           outStr = outStr + ", 环比呈下降趋势";
//         }
//       }
//     }
//     outStr = outStr + "。";
//     return outStr;
//   }
//   return outStr;
// }

// function getNfpDescribe(axisTime) {
//   let nfp_index = nfp_datas.findIndex((item) => item.time === axisTime)
//   let outStr = ""
//   if (nfp_index >= 0) {
//     outStr = `\nNFP为`;
//     outStr = outStr + nfp_datas[nfp_index].nfp_new;
//     if (!isNaN(nfp_datas[nfp_index].nfp_yoy)) {

//       if (+nfp_datas[nfp_index].nfp_yoy > 0) {
//         outStr = outStr + ", 同比上涨";
//         outStr = outStr + (nfp_datas[nfp_index].nfp_yoy * 100).toFixed(6).toLocaleString() + '%';
//       }
//       if (+nfp_datas[nfp_index].nfp_yoy < 0) {
//         outStr = outStr + ", 同比下跌";
//         outStr = outStr + (-nfp_datas[nfp_index].nfp_yoy * 100).toFixed(6).toLocaleString() + '%';
//       }
//     }
//     if (!isNaN(nfp_datas[nfp_index].nfp_qoq)) {

//       if (+nfp_datas[nfp_index].nfp_qoq > 0) {
//         outStr = outStr + ", 月环比上涨";
//         outStr = outStr + (nfp_datas[nfp_index].nfp_qoq * 100).toFixed(6).toLocaleString() + '%';
//       }
//       if (+nfp_datas[nfp_index].nfp_qoq < 0) {
//         outStr = outStr + ", 月环比下降";
//         outStr = outStr + (-nfp_datas[nfp_index].nfp_qoq * 100).toFixed(6).toLocaleString() + '%';
//       }
//     }

//     if (nfp_datas[nfp_index].nfp_new - nfp_datas[nfp_index].nfp_pred > 0) {
//       outStr = outStr + "。\n其数值高于";
//       outStr = outStr + "市场预期" + (Math.abs(nfp_datas[nfp_index].nfp_new - nfp_datas[nfp_index].nfp_pred) / nfp_datas[nfp_index].nfp_pred * 100).toFixed(4).toLocaleString() + '%';
//     } else if (nfp_datas[nfp_index].nfp_new - nfp_datas[nfp_index].nfp_pred < 0) {
//       outStr = outStr + "。\n其数值低于";
//       outStr = outStr + "市场预期" + (Math.abs(nfp_datas[nfp_index].nfp_new - nfp_datas[nfp_index].nfp_pred) / nfp_datas[nfp_index].nfp_pred * 100).toFixed(4).toLocaleString() + '%';
//     }

//     if (!isNaN(nfp_datas[nfp_index].nfp_yoy_compare) && !isNaN(nfp_datas[nfp_index].nfp_qoq_compare)) {
//       outStr = outStr + "。\n相较于前一个月"
//       if (+nfp_datas[nfp_index].nfp_yoy_compare > 0 && +nfp_datas[nfp_index].nfp_qoq_compare > 0) {
//         outStr = outStr + ", NFP的同比和环比均呈上升趋势"
//       }
//       else if (+nfp_datas[nfp_index].nfp_yoy_compare < 0 && +nfp_datas[nfp_index].nfp_qoq_compare < 0) {
//         outStr = outStr + ", NFP的同比和环比均呈下降趋势"
//       } else {
//         if (+nfp_datas[nfp_index].nfp_yoy_compare > 0) {
//           outStr = outStr + ", NFP的同比呈上升趋势";
//         }
//         else if (+nfp_datas[nfp_index].nfp_yoy_compare < 0) {
//           outStr = outStr + ", NFP的同比呈下降趋势";
//         }
//         if (+nfp_datas[nfp_index].nfp_qoq_compare > 0) {
//           outStr = outStr + ", 环比呈上升趋势";
//         }
//         else if (+nfp_datas[nfp_index].nfp_qoq_compare < 0) {
//           outStr = outStr + ", 环比呈下降趋势";
//         }
//       }
//     }
//     outStr = outStr + "。";
//     return outStr;
//   }
//   return outStr;
// }

// function getFedDescribe(axisTime) {
//   let fed_index = fed_datas.findIndex((item) => item.time === axisTime)
//   let outStr = ""
//   if (fed_index >= 0) {
//     outStr = `\nFED为`;
//     outStr = outStr + fed_datas[fed_index].fed_new;

//     if (!isNaN(fed_datas[fed_index].fed_qoq)) {

//       if (+fed_datas[fed_index].fed_qoq > 0) {
//         outStr = outStr + ", 月环比上涨";
//         outStr = outStr + (fed_datas[fed_index].fed_qoq * 100).toFixed(6).toLocaleString() + '%';
//       }
//       if (+fed_datas[fed_index].fed_qoq < 0) {
//         outStr = outStr + ", 月环比下降";
//         outStr = outStr + (-fed_datas[fed_index].fed_qoq * 100).toFixed(6).toLocaleString() + '%';
//       }
//     }

//     if (fed_datas[fed_index].fed_new - fed_datas[fed_index].fed_pred > 0) {
//       outStr = outStr + "。\n其数值高于";
//       outStr = outStr + "市场预期" + (Math.abs(fed_datas[fed_index].fed_new - fed_datas[fed_index].fed_pred) / fed_datas[fed_index].fed_pred * 100).toFixed(4).toLocaleString() + '%';
//     }
//     else if (fed_datas[fed_index].fed_new - fed_datas[fed_index].fed_pred < 0) {
//       outStr = outStr + "。\n其数值低于";
//       outStr = outStr + "市场预期" + (Math.abs(fed_datas[fed_index].fed_new - fed_datas[fed_index].fed_pred) / fed_datas[fed_index].fed_pred * 100).toFixed(4).toLocaleString() + '%';
//     }

//     outStr = outStr + "。";
//     return outStr;
//   }
//   return outStr;

// }

export function getSeries(data, useLogScale, dotCnt, showanalysis, TargetPoints, SpecialPointsValue, SpecialPointsLabel) {
  let res = [];

  // 数据
  res.push({
    data: data.map((item) => item.price),
    yAxisIndex: 0,
    type: "line",
    itemStyle: {
      color: "#7B74AB",
    },
    emphasis: {
      itemStyle: {
        color: "#FF3366",
      },
    },
    markPoint: {
      symbol: 'circle',
      symbolSize: 12,
      data: [],
      itemStyle: {
        color: '#FF3366',
      },
      label: {
        silent: true, //点击无响应
        show: true,
        formatter: (params) => {
          const index = data.findIndex((item) => item.time === params.data.coord[0]);
          const date = new Date(data[index].time);

          // 使用 Intl.DateTimeFormat 格式化日期
          const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Shanghai',
          }).format(date).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2');

          const price = data[index].price;
          let formattedPrice;

          // if (language_type.value == 'ch') {
          //   if (price >= 1000) {
          //     // 对于值大于等于 1000, 仅保留整数部分, 加上“美元”单位
          //     formattedPrice = Math.round(price) + " 美元";
          //   } else if (price >= 10) {
          //     // 对于值在 10 到 1000 之间, 保留两位小数, 加上“美元”单位
          //     formattedPrice = parseFloat(price.toFixed(2)) + " 美元";
          //   } else {
          //     // 对于值小于 10, 保留三位有效数字, 加上“美元”单位
          //     formattedPrice = parseFloat(Number(price).toFixed(dotCnt + 3)) + " 美元";
          //   }
          //   return `时间：${formattedDate} 价格：${formattedPrice}`;
          // }
          // else {
          if (price >= 1000) {
            // 对于值大于等于 1000, 仅保留整数部分, 加上“美元”单位
            formattedPrice = i18n.t("$") + Math.round(price).toLocaleString(undefined, { maximumFractionDigits: 0 });;
          } else if (price >= 10) {
            // 对于值在 10 到 1000 之间, 保留两位小数, 加上“美元”单位
            formattedPrice = i18n.t("$") + parseFloat(price.toFixed(2));
          } else {
            // 对于值小于 10, 保留三位有效数字, 加上“美元”单位
            formattedPrice = i18n.t("$") + parseFloat(Number(price).toFixed(dotCnt + 3));
          }
          return `${i18n.t("Time")}: ${formattedDate}\n ${i18n.t("Price")}: ${formattedPrice}`;
          // }
        },

        backgroundColor: '#222838',
        padding: [5, 5, 5, 5],
        borderRadius: 5,
        borderColor: '#323A52',
        borderWidth: 1,
        color: '#FFFFFF',
        position: 'top', // 标签位置
        distance: 20, // 标签与数据点的距离
        offset: [50, -5], // 相对于默认位置的偏移量
        lineHeight: 18,
      },
    },
  });

  // markline
  res.push({
    type: 'line',
    yAxisIndex: 1,
    markLine: getMarkLine(data, useLogScale),
  });

  res.push({
    type: 'line',
    yAxisIndex: 0,
    markPoint: {
      symbol: 'circle',
      symbolSize: 9,
      data: [],
      itemStyle: {
        color: '#2BBA96',
      },
      label: {
        silent: true, //点击无响应
        show: true,
        formatter: (params) => {
          const index = data.findIndex((item) => item.time === params.data.coord[0]);
          const date = new Date(data[index].time);
          const formattedDate = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Shanghai',
          }).format(date).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2');

          const price = data[index].price;
          let formattedPrice;

          // if (language_type.value == 'ch') {
          //   if (price >= 1000) {
          //     // 对于值大于等于 1000, 仅保留整数部分, 加上“美元”单位
          //     formattedPrice = Math.round(price) + " 美元";
          //   } else if (price >= 10) {
          //     // 对于值在 10 到 1000 之间, 保留两位小数, 加上“美元”单位
          //     formattedPrice = parseFloat(price.toFixed(2)) + " 美元";
          //   } else {
          //     // 对于值小于 10, 保留三位有效数字, 加上“美元”单位
          //     formattedPrice = parseFloat(Number(price).toFixed(dotCnt + 3)) + " 美元";
          //   }
          //   let outStr = `时间：${formattedDate}\n 价格：${formattedPrice}`;

          //   if (CBCPI.value)
          //     outStr = outStr + getCpiDescribe(params.data.coord[0])
          //   if (CBNFP.value)
          //     outStr = outStr + getNfpDescribe(params.data.coord[0])
          //   if (CBFED.value)
          //     outStr = outStr + getFedDescribe(params.data.coord[0])
          //   return outStr;
          // }
          // else {
          if (price >= 1000) {
            // 对于值大于等于 1000, 仅保留整数部分, 加上“美元”单位
            formattedPrice = i18n.t("$") + Math.round(price).toLocaleString(undefined, { maximumFractionDigits: 0 });;
          } else if (price >= 10) {
            // 对于值在 10 到 1000 之间, 保留两位小数, 加上“美元”单位
            formattedPrice = i18n.t("$") + parseFloat(price.toFixed(2));
          } else {
            // 对于值小于 10, 保留三位有效数字, 加上“美元”单位
            formattedPrice = i18n.t("$") + parseFloat(Number(price).toFixed(dotCnt + 3));
          }
          let outStr = `Time: ${formattedDate}</br>Price: ${formattedPrice}\n`;

          // if (CBCPI)
          //   outStr = outStr + getCpiDescribe(params.data.coord[0])
          // if (CBNFP)
          //   outStr = outStr + getNfpDescribe(params.data.coord[0])
          // if (CBFED)
          //   outStr = outStr + getFedDescribe(params.data.coord[0])
          return outStr;
          // }

        },
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderColor: '#2BBA96',
        borderRadius: 5,
        borderWidth: 1,
        position: 'top', // 标签位置
        distance: 5, // 标签与数据点的距离
        padding: [5, 5, 5, 5]
      },
    }
  });

  // 添加CPI NFP FED数据
  let pointData = []
  // if (CBCPI.value) {
  //   for (let i = 0; i < cpi_datas.length; i++) {
  //     let index = data.findIndex(item => item.time == cpi_datas[i].time);
  //     if (index < 0)
  //       continue;
  //     pointData.push({ coord: [data[index].time, data[index].price] });
  //   }
  // }
  // if (CBNFP.value) {
  //   for (let i = 0; i < nfp_datas.length; i++) {
  //     let index = data.findIndex(item => item.time == nfp_datas[i].time);
  //     if (index < 0)
  //       continue;
  //     pointData.push({ coord: [data[index].time, data[index].price] });
  //   }
  // }
  // if (CBFED.value) {
  //   for (let i = 0; i < fed_datas.length; i++) {
  //     let index = data.findIndex(item => item.time == fed_datas[i].time);
  //     if (index < 0)
  //       continue;
  //     pointData.push({ coord: [data[index].time, data[index].price] });
  //   }
  // }
  res[2].markPoint.data = pointData;

  if (showanalysis) {
    // 生成目标点的数据
    const targetPointsData = Array.from(TargetPoints).map((index) => ({
      coord: [data[index].time, data[index].price],
    }));
    const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    res.push({
      type: 'line',
      yAxisIndex: 0,
      markPoint: {
        silent: true,
        symbol: 'circle',
        symbolSize: 8,
        data: targetPointsData.map((pointData, index) => ({
          ...pointData,
          name: labels[index % 26] + (index >= 26 ? Math.floor(index / 26) : ""),  // 添加名字到数据点
        })),
        itemStyle: {
          color: '#0000FF', // 设置目标点为蓝色
        },
        label: {
          show: true,
          formatter: (params) => {
            const index = data.findIndex((item) => item.time === params.data.coord[0]);

            if (index < data.length - 5 && data[index + 5] && data[index + 5].price > params.data.coord[1]) {
              // 向下移动标签
              return '{a|}\n' + params.data.name;
            } else if (index > 0 && data[index - 1] && data[index - 1].price < params.data.coord[1]) {
              // 向上移动标签
              return params.data.name + '\n{a|}';
            } else {
              // 向下移动标签
              return '{a|}\n' + params.data.name;
            }
          },
          rich: {
            a: {
              height: 30
            }
          }
        },
      }
    });

    const specialPointsValue = SpecialPointsValue.map((value, index) => {
      return {
        coord: [data[value].time, data[value].price],
      }
    }).filter(Boolean);
    res.push({
      type: 'line',
      yAxisIndex: 0,
      markPoint: {
        silent: true,
        symbol: 'circle',
        symbolSize: 8,
        data: specialPointsValue.map((pointData, index) => ({
          ...pointData,
          name: SpecialPointsLabel[index],  // 添加名字到数据点
        })),
        itemStyle: {
          color: '#0000FF', // 设置标记点为蓝色
        },
        label: {
          show: true,
          formatter: (params) => {
            const index = data.findIndex((item) => item.time === params.data.coord[0]);

            if (index < data.length - 5 && data[index + 5] && data[index + 5].price > params.data.coord[1]) {
              // 向下移动标签
              return '{a|}\n' + params.data.name;
            } else if (index > 0 && data[index - 1] && data[index - 1].price < params.data.coord[1]) {
              // 向上移动标签
              return params.data.name + '\n{a|}';
            } else {
              // 向下移动标签
              return '{a|}\n' + params.data.name;
            }
          },
          rich: {
            a: {
              height: 30
            }
          }
        },
      }
    });
  }

  return res;
}

// function getLabelName(index) {
//   const labels = ['Max_Inc_St', 'Max_Inc_Ed', 'Max_Dec_St', 'Max_Dec_Ed'];
//   return labels[index % labels.length];
// }

function getDaysBetweenDates(date1, date2) {
  // 将日期字符串转换为 Date 对象
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const diffTime = Math.abs(d2 - d1); // 计算两个日期之间的毫秒数差
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));   // 将毫秒数转换为天数

  return diffDays;
}

// // X轴的间隔
export function getXAxiInterval(startDate, endDate) {
  const start = new Date(startDate.slice(0, 4), startDate.slice(4, 6) - 1, startDate.slice(6, 8));
  const end = new Date(endDate.slice(0, 4), endDate.slice(4, 6) - 1, endDate.slice(6, 8));
  let days = getDaysBetweenDates(start, end)
  let inter = 1
  inter += days / 10;
  return 24 * Math.floor(inter) - 1;
}

export function generateVisualMapPieces(inputArray, color1, color2, overlapColor, defaultColor) {
  if (!inputArray || inputArray.length === 0) {
    return [{
      min: 0,
      max: Infinity,
      color: defaultColor
    }];
  }

  let pieces = [];    // 提取区间
  let interval1 = { start: inputArray[0], end: inputArray[1] };
  let interval2 = { start: inputArray[2], end: inputArray[3] };

  // If intervals are the same, return them immediately
  if (interval1.start === interval2.start && interval1.end === interval2.end) {
    pieces.push({ min: interval1.start, max: interval1.end, color: overlapColor });
    return pieces;
  }

  // Sort intervals
  if (interval1.start > interval2.start) {
    let temp = interval1;
    interval1 = interval2;
    interval2 = temp;
    let tempcolor = color1;
    color1 = color2;
    color2 = tempcolor;
  }

  // Case: interval1.end < interval2.start, no overlap
  if (interval1.end < interval2.start) {
    if (interval1.start > 0) {
      pieces.push({ min: 0, max: interval1.start, color: defaultColor });
    }
    pieces.push({ min: interval1.start, max: interval1.end, color: color1 });
    pieces.push({ min: interval1.end, max: interval2.start, color: defaultColor });
    pieces.push({ min: interval2.start, max: interval2.end, color: color2 });
  }

  // Case: interval1.end >= interval2.start, overlap exists
  else {
    if (interval1.start > 0) {
      pieces.push({ min: 0, max: interval1.start, color: defaultColor });
    }
    pieces.push({ min: interval1.start, max: interval2.start, color: color1 });
    // Determine the end of overlap by the smaller end value
    let overlapEnd = Math.min(interval1.end, interval2.end);
    pieces.push({ min: interval2.start, max: overlapEnd, color: overlapColor });
    if (interval1.end > interval2.end) {
      pieces.push({ min: overlapEnd, max: interval1.end, color: color1 });
      pieces.push({ min: interval1.end, max: Infinity, color: defaultColor });
    } else if (interval2.end > interval1.end) {
      pieces.push({ min: overlapEnd, max: interval2.end, color: color2 });
      pieces.push({ min: interval2.end, max: Infinity, color: defaultColor });
    }
  }

  return pieces;
}

export const getAnalysisText = (cryptos, results_analysis, chartData, targetPoints) => {
  let labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S',
    'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  // output = '';
  let output = "";
  const points = Array.from(targetPoints);
  const startDate = new Date(chartData[points[0]].time);
  const endDate = new Date(chartData[points[points.length - 1]].time);

  const fmStartDate = new Intl.DateTimeFormat('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(startDate);

  const fmEndDate = new Intl.DateTimeFormat('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(endDate);

  output = output + cryptos + i18n.t(' Price trend: from ') + fmStartDate + i18n.t(' to ') + fmEndDate + '\n\n'

  output = output + results_analysis;

  for (let i = 0; i < points.length; i++) {
    const date = new Date(chartData[points[i]].time);
    // 自动显示为当前的时区, 不知道怎么做到的, 查查
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: 'Asia/Shanghai',
    }).format(date).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2');

    let newOutLabel = labels[i % 26];
    if (i >= 26)
      newOutLabel = newOutLabel + "" + Math.floor(i / 26).toString();
    //console.log("newOutLabel: "+newOutLabel)
    output = output + `\n${newOutLabel} : ${formattedDate}`
    if (i === 0) {
      output = output + ', ' + i18n.t('cycle begins') + '\n\n'
    } else if (i === points.length - 1) {
      output = output + ', ' + i18n.t('cycle ends') + '\n\n'
    } else {
      const timeDiff = Math.abs(date.getTime() - startDate.getTime());
      const dayDiff = timeDiff / (1000 * 3600 * 24);
      output = output + ', ' + i18n.t('passed') + ' ' + dayDiff.toFixed(1) + ' ' + i18n.t('days') + '\n\n'
    }
  }
  return output;
};

// function generateNowLastMonthTime() {
//   let now = new Date();
//   now.setMinutes(0, 0, 0, 0);
//   // let now = new Date("2023-06-06T14:00:00");
//   let start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000)
//   start.setHours(0, 0, 0, 0); // 将小时、分钟、秒和毫秒都设置为零
//   const gmtStartDate = start.toUTCString();
//   const gmtEndDate = now.toUTCString();

//   const gmtStartYear = gmtStartDate.substr(12, 4);
//   const gmtStartMonth = getMonthNumber(gmtStartDate.substr(8, 3));
//   const gmtStartDay = gmtStartDate.substr(5, 2);
//   const gmtStartHour = gmtStartDate.substr(17, 2);

//   const gmtEndYear = gmtEndDate.substr(12, 4);
//   const gmtEndMonth = getMonthNumber(gmtEndDate.substr(8, 3));
//   const gmtEndDay = gmtEndDate.substr(5, 2);
//   const gmtEndHour = gmtEndDate.substr(17, 2);

//   let startTimeGMT = gmtStartYear + "-" + gmtStartMonth + "-" + gmtStartDay + "T" + gmtStartHour + ":00:00.000Z"
//   let endTimeGMT = gmtEndYear + "-" + gmtEndMonth + "-" + gmtEndDay + "T" + gmtEndHour + ":00:00.000Z"
//   lastStartEndTime = [startTimeGMT, endTimeGMT];

//   console.log("GMT start: " + startTimeGMT)
//   console.log("GMT end: " + endTimeGMT)

// }

// const ShowLastMonth = (crypto, interact) => {
//   QueryLastMonthbuttonText.value = DuringQuery;
//   outputText.value = '';

//   dateOutOfRangeText.value = "";
//   myChart.clear();    // 清空图表数据
//   console.log("crypto: " + crypto)

//   if (allCryptos.includes(crypto)) {
//     console.log(allCryptos)
//     console.log("hava crypto")
//     generateLastMonthTime(crypto);
//   } else {
//     generateNowLastMonthTime();
//     console.log("not hava crypto")
//   }
//   lastCrypto = crypto;
//   axios.get(myUrl + "/data", {
//     params: {
//       c: crypto,
//       u: interact,
//       s: lastStartEndTime[0],
//       e: lastStartEndTime[1],
//       sa: "False",
//     }
//   }).then(res => {
//     const results = res.data.results;
//     ShowAnalysisValue = res.data.showanalysis === 'true';
//     chartData = results;

//     cpi_datas = res.data.cpi_data;
//     nfp_datas = res.data.nfp_data;
//     fed_datas = res.data.fed_data;

//     upDownPoints = [];
//     res.data.upDownPoints.forEach(element => {
//       upDownPoints.push(element);
//     });

//     const startDateValue = new Date(results[0].time);
//     const endDateValue = new Date(results[results.length - 1].time);

//     // format the date to YYYYMMDD format
//     const format = (date) => {
//       let month = '' + (date.getMonth() + 1),
//         day = '' + date.getDate(),
//         year = date.getFullYear();

//       if (month.length < 2)
//         month = '0' + month;
//       if (day.length < 2)
//         day = '0' + day;

//       return [year, month, day].join('');
//     }

//     startDate.value = format(startDateValue);
//     endDate.value = format(endDateValue);

//     const useLogScale = scaleType.value === 'log';
//     createChart(chartData, isFullscreen.value, useLogScale, true, upDownPoints, ShowAnalysisValue);

//     QueryLastMonthbuttonText.value = "查询上月数据"
//   }).catch(error => {
//     dateOutOfRangeText.value = "无数据！";
//     console.log(error)
//   })
// }

// 只在天的维度上进行判断
export function ifInDateRange(crypto, cryptoDateRange, setDateOutOfRangeText, startDate, endDate) {
  // 获取数据库中的时间区间
  const index = cryptoDateRange.findIndex((item) => item.crypto === crypto);
  let myStart = new Date(cryptoDateRange[index].startDate);
  let myEnd = new Date(cryptoDateRange[index].endDate);
  console.log(crypto + "已有时间区间 " + myStart + " " + myEnd);
  myStart.setHours(0, 0, 0, 0); //精确到天
  myEnd.setHours(0, 0, 0, 0);

  // 要判断的时间
  const startYear = startDate.substr(0, 4);
  const startMonth = startDate.substr(4, 2);
  const startDay = startDate.substr(6, 2);
  const endYear = endDate.substr(0, 4);
  const endMonth = endDate.substr(4, 2);
  const endDay = endDate.substr(6, 2);

  // 我们要查寻的时间区间 目前是当地时间
  let start = startYear + '-' + startMonth + '-' + startDay + 'T00:00:00';
  let end = endYear + '-' + endMonth + '-' + endDay + 'T00:00:00';
  const startDateObj = new Date(start);
  const endDateObj = new Date(end);

  console.log("判断时间区间 " + myStart + " " + myEnd + " " + startDateObj + " " + endDateObj)

  let dateOutOfRangeText = "";
  if (startDateObj >= myStart && endDateObj <= myEnd && startDateObj <= endDateObj) {
    if (dateOutOfRangeText !== "") setDateOutOfRangeText('');
    return true;
  } else if (startDateObj > endDateObj) {
    dateOutOfRangeText = i18n.t("The start date should be less than or equal to the end date!")
  } else {
    let day1 = myStart.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
      timeZone: 'Asia/Shanghai',
    }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2').split(",")[0];

    let day2 = myEnd.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
      timeZone: 'Asia/Shanghai',
    }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3/$1/$2').split(",")[0];
    // if (language_type.value == 'ch') {
    //   dateOutOfRangeText.value = "无数据, 请在" + day1 + " 至 " + day2 + "之间重新选择日期区间！"
    // } else {
    dateOutOfRangeText = i18n.t("Please re-select the date interval between") + " " + day1 + " " + i18n.t('and') + ' ' + day2 + i18n.t("!");
    // }
  }
  setDateOutOfRangeText(dateOutOfRangeText)
  return false;

}

// 将月份从英文转为数字
function getMonthNumber(monthStr) {
  const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  const monthIndex = monthAbbreviations.indexOf(monthStr);

  const monthNumber = monthIndex + 1;

  return monthNumber;
}

export function generateLastWeekTime(crypto, cryptoDateRange) {
  const index = cryptoDateRange.findIndex((item) => item.crypto === crypto);

  let cryptoEnd = new Date(cryptoDateRange[index].endDate);

  let cryptoStart = new Date(cryptoEnd.getTime() - 7 * 24 * 60 * 60 * 1000)
  cryptoStart.setHours(0, 0, 0, 0); // 将小时、分钟、秒和毫秒都设置为零
  // 转GMT
  const gmtStartDate = cryptoStart.toUTCString();
  const gmtEndDate = cryptoEnd.toUTCString();

  const gmtStartYear = gmtStartDate.substr(12, 4);
  const gmtStartMonth = getMonthNumber(gmtStartDate.substr(8, 3));
  console.log('gmtStartMonth:', gmtStartMonth)
  const gmtStartDay = gmtStartDate.substr(5, 2);
  const gmtStartHour = gmtStartDate.substr(17, 2);

  const gmtEndYear = gmtEndDate.substr(12, 4);
  const gmtEndMonth = getMonthNumber(gmtEndDate.substr(8, 3));
  const gmtEndDay = gmtEndDate.substr(5, 2);
  const gmtEndHour = gmtEndDate.substr(17, 2);

  let startTimeGMT = gmtStartYear + "-" + gmtStartMonth + "-" + gmtStartDay + "T" + gmtStartHour + ":00:00.000Z"
  let endTimeGMT = gmtEndYear + "-" + gmtEndMonth + "-" + gmtEndDay + "T" + gmtEndHour + ":00:00.000Z"
  const lastStartEndTime = [startTimeGMT, endTimeGMT];

  console.log("GMT start: " + startTimeGMT)
  console.log("GMT end: " + endTimeGMT)

  return lastStartEndTime;
}

export function generateTimeByDate(date) {
  let newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  const gmtDate = newDate.toUTCString();

  const gmtYear = gmtDate.substr(12, 4);
  const gmtMonth = getMonthNumber(gmtDate.substr(8, 3));
  const gmtDay = gmtDate.substr(5, 2);
  const gmtHour = gmtDate.substr(17, 2);
  let dateTimeGMT = gmtYear + "-" + gmtMonth + "-" + gmtDay + "T" + gmtHour + ":00:00.000Z"
  return dateTimeGMT;
}


// 获取日历时间, 转为当地时间, 再转为GMT时间
export function genarateLastTimeGMT(startDate, endDate) {
  const startYear = startDate.slice(0, 4);
  const startMonth = startDate.slice(4, 6);
  const startDay = startDate.slice(6, 8);
  const endYear = endDate.slice(0, 4);
  const endMonth = endDate.slice(4, 6);
  const endDay = endDate.slice(6, 8);

  // 我们要查寻的时间区间 目前是当地时间
  let start = startYear + '-' + startMonth + '-' + startDay + 'T00:00:00';
  let end = endYear + '-' + endMonth + '-' + endDay + 'T23:00:00';

  const localStartDate = new Date(start);
  const localEndDate = new Date(end);

  // 转为GMT时间
  const gmtStartDate = localStartDate.toUTCString();
  const gmtEndDate = localEndDate.toUTCString();

  const gmtStartYear = gmtStartDate.substr(12, 4);
  const gmtStartMonth = getMonthNumber(gmtStartDate.substr(8, 3));
  const gmtStartDay = gmtStartDate.substr(5, 2);
  const gmtStartHour = gmtStartDate.substr(17, 2);

  const gmtEndYear = gmtEndDate.substr(12, 4);
  const gmtEndMonth = getMonthNumber(gmtEndDate.substr(8, 3));
  const gmtEndDay = gmtEndDate.substr(5, 2);
  const gmtEndHour = gmtEndDate.substr(17, 2);

  let startTimeGMT = gmtStartYear + "-" + gmtStartMonth + "-" + gmtStartDay + "T" + gmtStartHour + ":00:00.000Z"
  let endTimeGMT = gmtEndYear + "-" + gmtEndMonth + "-" + gmtEndDay + "T" + gmtEndHour + ":00:00.000Z"
  const lastStartEndTime = [startTimeGMT, endTimeGMT];

  console.log("GMT start: " + startTimeGMT)
  console.log("GMT end: " + endTimeGMT)
  return lastStartEndTime;
}


// const ShowData = (crypto, interact) => {
//   showAnalysis.value = 'false';

//   if (allCryptos.includes(crypto)) {
//     // 在这里进行
//     if (!ifInDateRange(crypto)) {
//       console.log('日期不合法')
//       return;
//     }
//   }

//   genarateLastTimeGMT();
//   lastCrypto = crypto;
//   QueryDatabuttonText.value = DuringQuery;

//   outputText.value = '';
//   myChart.clear();    // 清空图表数据

//   axios.get(myUrl + "/data", {
//     params: {
//       c: crypto,
//       u: interact,
//       s: lastStartEndTime[0],
//       e: lastStartEndTime[1],
//       sa: "False",
//       news: checkboxNEWS.value,
//     }
//   }).then(res => {
//     cpi_datas = res.data.cpi_data;
//     nfp_datas = res.data.nfp_data;
//     fed_datas = res.data.fed_data;

//     upDownPoints = [];
//     res.data.upDownPoints.forEach(element => {
//       upDownPoints.push(element);
//     });

//     const results = res.data.results;
//     ShowAnalysisValue = res.data.showanalysis === 'true';
//     chartData = results;

//     const startDateValue = new Date(results[0].time);
//     const endDateValue = new Date(results[results.length - 1].time);

//     // format the date to YYYYMMDD format
//     const format = (date) => {
//       let month = '' + (date.getMonth() + 1),
//         day = '' + date.getDate(),
//         year = date.getFullYear();

//       if (month.length < 2)
//         month = '0' + month;
//       if (day.length < 2)
//         day = '0' + day;

//       return [year, month, day].join('');
//     }

//     startDate.value = format(startDateValue);
//     endDate.value = format(endDateValue);

//     const useLogScale = scaleType.value === 'log';
//     createChart(chartData, isFullscreen.value, useLogScale, true, upDownPoints, ShowAnalysisValue);
//     QueryDatabuttonText.value = "查询";
//   }).catch(error => {
//     dateOutOfRangeText.value = "无数据！";
//     console.log(error)
//   })
// }

// const generateAnalysis = (interact) => {
//   showAnalysis.value = 'true';
//   AnalysisbuttonText.value = DuringQuery;

//   // 没有选择红点数据
//   if (selectedPoints.size === 0) {
//     axios.get(myUrl + "/data", {
//       params: {
//         c: lastCrypto,
//         u: interact,
//         s: lastStartEndTime[0],
//         e: lastStartEndTime[1],
//         sa: "True",
//         regen: "False",
//         news: checkboxNEWS.value,
//         start_day: startDate.value,
//         end_day: endDate.value,
//       }
//     }).then(res => {
//       const results = res.data.results;
//       ShowAnalysisValue = res.data.showanalysis === 'true';

//       // TargetPoints 存放ABCD点的index
//       TargetPoints.clear();
//       res.data.endpoints.forEach(element => {
//         TargetPoints.add(element);
//       });

//       upDownPoints = [];
//       res.data.upDownPoints.forEach(element => {
//         upDownPoints.push(element);
//       });

//       SpecialPointsValue = [];
//       for (let i = 0; i < res.data.specialpoints.length; i++) {
//         SpecialPointsValue.push(res.data.specialpoints[i]);
//       }
//       console.log(SpecialPointsValue);

//       SpecialPointsLabel = [];
//       for (let i = 0; i < res.data.specialpointsname.length; i++) {
//         SpecialPointsLabel.push(res.data.specialpointsname[i]);
//       }
//       console.log(SpecialPointsLabel);

//       const useLogScale = scaleType.value === 'log';
//       createChart(chartData, isFullscreen.value, useLogScale, false, upDownPoints, ShowAnalysisValue);

//       results_analysis.value = res.data.results_analysis;
//       results_analysis_en.value = res.data.results_analysis_en;
//       console.log(results_analysis.value);
//       console.log(results_analysis_en.value);
//       if (language_type.value == 'ch') {
//         ShowAnalysis(lastCrypto, results_analysis);
//       }
//       else {
//         ShowAnalysis(lastCrypto, results_analysis_en);
//       }

//       if (language_type.value == 'ch') {
//         AnalysisbuttonText.value = "生成走势描述"
//       }
//       else {
//         AnalysisbuttonText.value = "Generate Trend Description";
//       }

//     }).catch(error => {
//       console.log(error)
//     })

//   } else {
//     //向后端传入的 标点数据
//     // let pointstime = [];
//     // selectedPoints.forEach(function(pointIndex) {
//     //     let pointTime = xAxisData.value[pointIndex];
//     //     pointstime.push(pointTime);
//     // });
//     let pointsTime = [];

//     selectedPoints.forEach(function (pointIndex) {
//       let time = xAxisData.value[pointIndex];
//       pointsTime.push(time);
//     });
//     if (!pointsTime.includes(xAxisData.value[0]))
//       pointsTime.push(xAxisData.value[0]);
//     if (!pointsTime.includes(xAxisData.value[xAxisData.value.length - 1]))
//       pointsTime.push(xAxisData.value[xAxisData.value.length - 1]);

//     axios.get(myUrl + "/data", {
//       params: {
//         c: lastCrypto,
//         u: interact,
//         s: lastStartEndTime[0],
//         e: lastStartEndTime[1],
//         t: pointsTime, // 在这里传入时间数组
//         sa: "True",
//         regen: "True",
//         news: checkboxNEWS.value,
//         start_day: startDate.value,
//         end_day: endDate.value,
//       }
//     }).then(res => {
//       ShowAnalysisValue = res.data.showanalysis === 'true';

//       TargetPoints.clear();
//       res.data.endpoints.forEach(element => {
//         TargetPoints.add(element);
//       });

//       upDownPoints = [];
//       res.data.upDownPoints.forEach(element => {
//         upDownPoints.push(element);
//       });

//       SpecialPointsValue = [];
//       for (let i = 0; i < res.data.specialpoints.length; i++) {
//         SpecialPointsValue.push(res.data.specialpoints[i]);
//       }
//       console.log(SpecialPointsValue);

//       SpecialPointsLabel = [];
//       for (let i = 0; i < res.data.specialpointsname.length; i++) {
//         SpecialPointsLabel.push(res.data.specialpointsname[i]);
//       }

//       const useLogScale = scaleType.value === 'log';
//       createChart(chartData, isFullscreen.value, useLogScale, false, upDownPoints, ShowAnalysisValue);

//       results_analysis.value = res.data.results_analysis;
//       results_analysis_en.value = res.data.results_analysis_en;
//       if (language_type.value == 'ch') {
//         ShowAnalysis(lastCrypto, results_analysis);
//       }
//       else {
//         ShowAnalysis(lastCrypto, results_analysis_en);
//       }

//       // format the date to YYYYMMDD format


//       if (language_type.value == 'ch') {
//         AnalysisbuttonText.value = "生成走势描述"
//       }
//       else {
//         AnalysisbuttonText.value = "Generate Trend Description";
//       }

//     }).catch(error => {
//       console.log(error)
//     })
//   }
// }

// const handleFullscreenChange = () => {
//   if (document.fullscreenElement) {
//     buttonText.value = '退出全屏';
//     isFullscreen.value = true;
//     showElements.value = false;
//   } else {
//     buttonText.value = '全屏显示';
//     isFullscreen.value = false;
//     showElements.value = true;
//     chart.value.style.height = "500px";
//   }
//   myChart.resize();
// };

// format the date to YYYYMMDD format
export const formatDateToDay = (date) => {
  let month = '' + (date.getMonth() + 1),
    day = '' + date.getDate(),
    year = date.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('');
}