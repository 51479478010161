import _ from 'lodash';
import config from '../config';
// import { mergeUrl } from './url';

// import { RequestState } from '../utils/constants';
// import Storage from '../utils/storage';


// const { REQUEST, SUCCESS, FAIL, NOT_AUTHORIZED } = RequestState;

const BASE_URL = config.chatbotApi.host + ':' + config.chatbotApi.port || 'http://localhost:16161'
// const BASE_URL = 'http://54.206.93.57:16161'

// ===========================
// HELPERS
// ===========================

export function fetchOptions(method = 'get', body, headers = {}, mode = 'cors') {
  return {
    method,
    headers: {
      ...(body ? { 'Content-Type': 'application/json' } : {}),
      ...headers,
    },
    body: JSON.stringify(body),
    // credentials: 'include',
    mode,
  };
}

export function restUrl(url, params, host) {
  if (url.indexOf("http") !== -1) {
    return mergeUrl(url, params);
  }
  return mergeUrl(host + url, params);
}

export function isErrorResponse(code, json) {
  return Boolean(
    !json ||
    (code && code !== 200 && code !== 204) ||
    (json.code && json.code !== 200 && json.code !== 204) ||
    json.status === "ERROR" ||
    json.status === "FAIL");
}

export function paramsToString(obj) {
  let parts = [];
  for (let i in obj) {
    let value = obj[i];
    if (_.isNil(value))
      continue;
    value = _.castArray(value);
    value.forEach(v => {
      if (obj.hasOwnProperty(i) && !_.isNil(obj[i])) {
        parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(v));
      }
    })
  }
  console.log('parts:', parts)
  return parts.join("&");
}

export function mergeUrl(url, params, urlMap) {
  if (!url) {
    console.error("URL is not defined for action: ", params);
  }
  if (!params)
    return url;
  params = { ...params };
  console.log('params:', params)
  if (urlMap) {
    _.keys(urlMap).forEach(key => {
      console.log('key:', key)
      params[urlMap[key]] = params[key];
      delete params[key];
    });
  }
  if (url.indexOf(":") !== -1) {
    _.forEach(url.match(/:([0-9_a-z]+)/gi), match => {
      let key = match.replace(":", "");
      if (_.isNil(params[key]))
        return;
      url = url.replace(match, params[key]);
      delete params[key];
    });
  }

  if (_.keys(params).length > 0) {
    if (url.indexOf("?") !== -1) {
      url = url + "&" + paramsToString(params);
    } else {
      url = url + "?" + paramsToString(params);
    }
  }
  return url;
}

export function authHeader() {
  const token = localStorage.getItem('token')
  if (token) {
    return {
      'Authorization': `Bearer ${token}`
    }
  }
}

// export function platformAuthHeaders(session, token) {
//   session = session ?? Storage.getUserPlatformSession();
//   token = token ?? Storage.getUserPlatformToken();
//   if (session && token) {
//     return {
//       'X-Auth-Session': session,
//       'X-Auth-Token': token,
//     }
//   }
// }


// ===========================
// FETCH FUNCTION
// ===========================
async function _fetch(args) {
  let {
    url, params, method,
    action, body, headers, host = BASE_URL, mode
  } = args;

  url = restUrl(url, params, host);
  let options = fetchOptions(method, body, headers, mode);

  // Do Fetch
  let response;
  try {
    response = await fetch(url, options);
  } catch (err) { // NETWORK ERROR
    if (typeof err === 'string') {
      console.log("URL is unreachable: ", url, err);
      return Promise.reject({ message: err });
    }
    console.log(err);
    return Promise.reject(err);
  }

  let json = {}
  try {
    if (response.status !== 204) {
      json = await response?.json();
    }
  } catch (e) { // JSON PARSE ERROR
    console.log('err: ' + e?.message)
  }

  // RESPONSE ERRORS
  if (isErrorResponse(response.status, json)) {
    console.log("API ERROR", json, response,);
    let err = {
      message: json.message || json.status || 'Unknown Error',
      code: json.code || response.status || 'Unknown Code',
    }

    return Promise.reject({ ...err, action });
  }

  json.headers = headers;
  return Promise.resolve(json);
}


// ===========================
// REQUST TYPES
// ===========================
export const get = (options) => _fetch({ ...options, method: 'get' });
export const post = (options) => _fetch({ ...options, method: 'post' });
export const put = (options) => _fetch({ ...options, method: 'put' });
export const del = (options) => _fetch({ ...options, method: 'delete' });

