export const LanguageOptions = [
  { value: 'en', label: 'English' },
  { value: 'zh-CN', label: '简体中文' },
  { value: 'ko', label: '한국인' },
  { value: 'es', label: 'Español' }
]

export const DefaultLauageOption = { value: 'en', label: 'English' }

export const CoinOptions = [
  { value: 'bitcoin', label: "Bitcoin(BTC)" },
  { value: 'ethereum', label: "Ethereum(ETH)" },
  { value: 'binance-coin', label: "Binance(BNB)" },
  { value: 'solana', label: "Solana(SOL)"},
  { value: 'xrp', label: "XRP"},
  { value: 'dogecoin', label: "Dogecoin(DOGE)"},
  { value: 'tron', label: "TRON(TRX)"},
  { value: 'avalanche', label: "Avalanche(AVAX)"},
  { value: 'cardano', label: "Cardano(ADA)"},
  { value: 'shiba-inu', label: "Shiba Inu(SHIB)"},
  { value: 'wrapped-bitcoin' , label:"Wrapped Bitcoin(WBTC)"},
  { value: 'chainlink' , label:"Chainlink(LINK)"},
  { value: 'bitcoin-cash' , label:"Bitcoin Cash(BCH)"},
  { value: 'polkadot' , label:"Polkadot(DOT)"},
  { value: 'litecoin' , label:"Litecoin(LTC)"},
  { value: 'near-protocol' , label:"NEAR Protocol(NEAR)"},
  { value: 'uniswap' , label:"Uniswap(UNI)"},
  { value: 'internet-computer' , label:"Internet Computer(ICP)"},
  { value: 'monero' , label:"Monero(XMR)"},
  { value: 'unus-sed-leo', label:"Unus Sed Leo(LEO)"},
  { value: 'stellar', label:"Stellar(XLM)"},
  { value: 'ethereum-classic', label:"Ethereum Classic(ETC)"},
  { value: 'stacks', label:"Stacks(STX)"},
  { value: 'filecoin', label:"Filecoin(FIL)"},
  { value: 'crypto-com-coin', label:"Crypto.com Coin(CRO)"},
  { value: 'cosmos', label:"Cosmos(ATOM)"},
  { value: 'theta', label:"Theta"},
  { value: 'lido-dao', label:"Lido DAO(LDO)"},
  { value: 'maker', label:"Maker(MKR)"},
  { value: 'fantom', label:"Fantom(FTM)"},
  { value: 'thorchain', label:"THORChain(RUNE)"},
  { value: 'vechain', label:"VeChain(VET)"},
  { value: 'injective-protocol', label:"Injective(INJ)"},
  { value: 'the-graph', label:"The Graph(GRT)"},
  { value: 'kucoin-token', label:"Kucoin Token(KCS)"},
  { value: 'arweave', label:"Arweave(AR)"},
  { value: 'helium', label:"Helium(HNT)"},
  { value: 'algorand', label:"Algorand(ALGO)"},
  { value: 'bitcoin-sv', label:"Bitcoin SV(BSV)"},
  { value: 'polygon', label:"Polygon(MATIC)"},
  { value: 'gatetoken', label:"GateToken(GT)"},
  { value: 'axie-infinity', label:"Axie Infinity(AXS)"},
  { value: 'hedera-hashgraph', label:"Hedera Hashgraph(HBAR)"},
  { value: 'ecash', label:"eCash(XEC)"},
  { value: 'quant', label:"Quant(QNT)"},
  { value: 'conflux-network', label:"Conflux(CFX)"},
  { value: 'tezos', label:"Tezos(XTZ)"},
  { value: 'the-sandbox', label:"The Sandbox(SAND)"},
  { value: 'elrond-egld', label:"MultiversX(EGLD)"},
  { value: 'decentraland', label:"Decentraland(MANA)"},
  { value: 'chiliz', label:"Chiliz(CHZ)"}
]

export const DefaultCoinOption = { value: 'bitcoin', label: "Bitcoin(btc)" }

export const RequestState = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
}

export const LoadingState = {
  'INITIAL': 0,
  'LOADING': 1,
  'LOADED': 2
}

export const ModelOptions = [
  { value: 'llm', label: 'Deep Search (Llama3 on EdgeCloud based)' },
  { value: 'deep_search', label: 'Deep Search (GPT4 based)' },
  { value: 'chatgpt', label: 'ChatGPT' },
  { value: 'llama3', label: 'Llama3 on EdgeCloud' },

]
