import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './translations/en.json';
import zhCNTranslation from './translations/zh-CN.json';
import koTranslation from './translations/ko.json';
import esTranslation from './translations/es.json';

function getLanguagePreference() {
  const storedLanguageStr = localStorage.getItem('crypto-gpt-language');
  const storedLanguage = JSON.parse(storedLanguageStr)
  return storedLanguage ? storedLanguage.value || 'en' : 'en';
}

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      'zh-CN': {
        translation: zhCNTranslation,
      },
      ko: {
        translation: koTranslation
      },
      es: {
        translation: esTranslation
      }
    },
    lng: getLanguagePreference(),
    fallbackLng: 'en', 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
