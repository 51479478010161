import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { post } from '../service/fetch';
import { useParams } from 'react-router-dom';
import { FiUser } from "react-icons/fi";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Markup } from 'react-render-markup';

function SharePage() {
  const { t } = useTranslation();
  const { shareId } = useParams();
  const [isValid, setIsValid] = useState(true);
  const [chatInfo, setChatInfo] = useState({
    date: '', title: '', history: []
  })

  useEffect(() => {
    fetchHistory();

    async function fetchHistory() {
      if (!isValid) setIsValid(true);
      try {
        const response = await post({
          url: "/loadShareHistory",
          body: {
            historyId: shareId
          }
        })
        console.log('response:', response)

        let history = response.history;
        if (history.length === 0) {
          setIsValid(false);
        } else {
          setChatInfo({
            date: response.date,
            title: response.title.replaceAll('\n', ''),
            history: response.history
          });
        }
      } catch (e) {
        console.log('Error in fetchHistory:', e)
      }
    }
  }, [shareId, isValid])

  return <div className={'SharePage'}>
    {shareId && isValid ? <>
      <div className='SharePage__header'>{t('Shared Chat')}</div>
      <div className='SharePage__title'>
        <div className='SharePage__title--main'>{chatInfo.title || 'New Chat'}</div>
        <div className='SharePage__title--sub'>{chatInfo.date}</div>
      </div>
      <div className='SharePage__content'>
        {chatInfo.history.map((chat, i) => <div key={i}>
          {chat.question && <div className='SharePage__chat-row question'>
            <div className='SharePage__chat-sender'>
              <FiUser />
            </div>
            <div className='SharePage__chat-content'>
              <Markdown remarkPlugins={[remarkGfm]} className="markdown" rehypePlugins={[rehypeRaw]}>
                {chat.question}
              </Markdown>
            </div>
          </div>}
          {chat.response && <div className='SharePage__chat-row'>
            <div className='SharePage__chat-sender system'>
            </div>
            <div className='SharePage__chat-content'>
              <Markup markup={chat.response} />
            </div>
          </div>}
        </div>)}
      </div>
    </> : <div className='SharePage__empty'>
      <div className='SharePage__empty--left'>
        Error
      </div>
      <div className='SharePage__empty--right'>
        This page could not be found
      </div>
    </div>}
  </div>
}

export default SharePage;