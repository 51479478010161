import React, { useState, useRef } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { LanguageOptions, DefaultLauageOption } from '../utils/constants';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { BsChatLeft } from "react-icons/bs";
import { IoShareOutline } from "react-icons/io5";
import { RiDeleteBin5Line, RiAddFill } from "react-icons/ri";
import { AiOutlineEdit, AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { post, authHeader } from '../service/fetch';
import ShareLinkModal from './ShareLinkModal';
import { LoadingState } from '../utils/constants';
import DeleteChatModal from './DeleteChatModal';
import config from '../config';

function NavBarSide(props) {
  const { chatList, handleSetCurChat, curChat, deleteChat, handleNewChat,
    curChatTitle, chatHistory, fetchChatList, toggleSideBar } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [showShareLinkModal, setShowShareLinkModal] = useState(false);
  const [showDeleteChatModal, setShowDeleteChatModal] = useState(false);
  const [loadingState, setLoadingState] = useState(LoadingState.INITIAL);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedTab, setSelectedTab] = useState(localStorage.getItem('tabName') || 'chat');
  const { t, i18n } = useTranslation();
  const titleRef = useRef();

  const userName = localStorage.getItem('username') || "User";
  const isUnverifiedUser = localStorage.getItem('verify_token') !== 'verified'
  const isAnonymous = userName === 'ausr' || isUnverifiedUser;
  const language = JSON.parse(localStorage.getItem('crypto-gpt-language')) || DefaultLauageOption;
  const navigate = useNavigate();
  const location = useLocation();

  let todayTitleDisplayed = false;
  let lastWeektitleDisplayed = false;
  let lastMonthTitleDisplayed = false;
  let earlyTitleDisplayed = false;

  const todayTS = ~~(new Date().setHours(0, 0, 0) / 1000)
  const lastWeekDate = new Date();
  lastWeekDate.setDate(lastWeekDate.getDate() - 7);
  const lastMonthDate = new Date();
  lastMonthDate.setDate(lastMonthDate.getDate() - 30);
  const lastWeekTS = ~~(lastWeekDate.setHours(0, 0, 0) / 1000)
  const lastMonthTS = ~~(lastMonthDate.setHours(0, 0, 0) / 1000)
  // console.log('ts:', todayTS, lastWeekTS, lastMonthTS)
  const changeLanguage = (option) => {
    localStorage.setItem('crypto-gpt-language', JSON.stringify(option))
    i18n.changeLanguage(option.value);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('user-id');
    localStorage.removeItem('verify_token');
    localStorage.removeItem('email');
    localStorage.setItem('logoutSignal', Date.now().toString());
    navigate('../login');
  }

  const handleChangeCurChat = (chatId, title) => {
    if (curChat === chatId) return;
    handleSetCurChat(chatId, title);
  }

  const handleDeleteChat = async (chatId) => {
    const res = await deleteChat(chatId);
    if (res) {
      setShowDeleteChatModal(false);
    }
  }

  const handleShare = async () => {
    setShowShareLinkModal(true);
  }

  const handleCopy = async () => {
    try {
      setLoadingState(LoadingState.LOADING);
      const response = await post({
        url: "/getShareUrl",
        body: {
          chatId: curChat
        },
        headers: authHeader()
      })
      const url = response.url;
      const host = config.fe.host || 'http://localhost';
      const port = config.fe.port || '3000'
      const shareLink = port === 443 ? `${host}/share/${url}` : `${host}:${port}/share/${url}`;
      setLoadingState(LoadingState.LOADED);
      setTimeout(() => {
        setShowShareLinkModal(false);
        setLoadingState(LoadingState.INITIAL);
        copyToClipboard(shareLink)
      }, 1000)
    } catch (e) {
      setLoadingState(LoadingState.INITIAL);
      if (e.code === 401) {
        navigate('/login')
      }
    }
  }

  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    // alert("Text copied to clipboard: " + text);
    // // Copied share link '+text+' to clipboard!
    setTimeout(() => {
      alert(t("Copied share link") + " " + text + " " + t("to clipboard") + t("!"));
    }, 1000)
  };

  const handleUpdateTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  const handleSubmit = async () => {
    const newTitle = titleRef.current.value;
    try {
      const response = await post({
        url: "/updateChatTitle",
        body: {
          chatId: curChat,
          title: newTitle
        },
        headers: authHeader()
      })
      if (response.status === 'success') {
        fetchChatList();
      }
      setIsEditing(false);
    } catch (e) {
      if (e.code === 401) {
        navigate('/login')
      }
    }
  }

  const handleTab = (tab) => {
    const currentPath = location.pathname;
    if (currentPath === '/request-pwd-reset') {
      navigate('../');
    }
    localStorage.setItem('tabName', tab)
    setSelectedTab(tab);
  }

  const handleLink = (chatId, title) => {
    navigate(`../c/${chatId}`);
    handleChangeCurChat(chatId, title);
    // toggleSideBar();
  }


  return <div className='NavBarSide'>
    <div className='NavBarSide__logo-container'>
      <Link to={config.fe.host || '#'}>
        <div className='NavBarSide__logo'></div>
      </Link>
    </div>
    <div className='NavBarSide__mobile-header'>
      <div className='NavBarSide__mobile-close' onClick={toggleSideBar}></div>
    </div>
    <div className='NavBarSide__sections'>
      <div className='NavBarSide__sections--half'>
        {/* <Link to={config.fe.host || '#'}> */}
        <div className={classNames('NavBarSide__section', { 'active': selectedTab === 'home' })} onClick={() => handleTab('home')}>{t('HOME')}</div>
        {/* </Link> */}
        {/* <div className='NavBarSide__section'>{t('ABOUT')}</div> */}
        {/* <Link to={config.fe.host || '#'}> */}
        <div className={classNames('NavBarSide__section', { 'active': selectedTab === 'chat' })} onClick={() => handleTab('chat')}>{t('CHAT')}</div>
        {/* </Link> */}
        {/* <a target='_blank' rel="noreferrer"
          href={'https://docs.google.com/forms/d/e/1FAIpQLSfPN2614IHsvDu6I_RmEGglZvbnld2XLEF2Yv-Kk8URjD0msA/viewform'}>
          <div className={classNames('NavBarSide__section')} >{t('Feedback')}</div>
        </a> */}
      </div>
      <div className='NavBarSide__sections--half right'>
        <div className='NavBarSide__section select'>
          <Select
            className="language-select-container"
            classNamePrefix="language-select"
            value={language}
            options={LanguageOptions}
            onChange={(e) => changeLanguage(e)} />
        </div>
        <div className='NavBarSide__section button'>
          <div className='NavBarSide__section--button' onClick={handleNewChat}>
            <div><RiAddFill /></div>
            {t('New Chat')}
          </div>
        </div>
      </div>
    </div >
    <div className='NavBarSide__content standard-scrollbar'>
      {chatList.map(chat => {
        const isActive = curChat === chat.chat_id;
        let title = null;

        if (chat.update_time >= todayTS && !todayTitleDisplayed) {
          title = <div className='NavBarSide__chat-title'>{t('Today')}</div>
          todayTitleDisplayed = true;
        } else if (chat.update_time < todayTS && chat.update_time >= lastWeekTS && !lastWeektitleDisplayed) {
          title = <div className='NavBarSide__chat-title'>{t('Previous 7 days')}</div>
          lastWeektitleDisplayed = true;
        } else if (chat.update_time < lastWeekTS && chat.update_time >= lastMonthTS && !lastMonthTitleDisplayed) {
          title = <div className='NavBarSide__chat-title'>{t('Previous 30 days')}</div>
          lastMonthTitleDisplayed = true;
        } else if (chat.update_time < lastMonthTS && !earlyTitleDisplayed) {
          title = <div className='NavBarSide__chat-title'>{t('Early')}</div>
          earlyTitleDisplayed = true;
        }

        return <div key={chat.chat_id}>
          {title}
          <div className={classNames('NavBarSide__chat', { 'active': isActive, 'isAnonymous': isAnonymous })} onClick={() => handleLink(chat.chat_id, chat.title)} >
            {isActive && !isAnonymous ? <div className='NavBarSide__chat--icon' onClick={() => handleShare(chat.chat_id, chat.title)}><IoShareOutline /></div> :
              <div className='NavBarSide__chat--icon'><BsChatLeft /></div>}
            {isActive && isEditing && !isAnonymous ? <div className='NavBarSide__chat--title-container'>
              <input type='text' ref={titleRef}
                defaultValue={chat.title}
                placeholder='Enter your chat title'
                onKeyDown={handleUpdateTitleKeyDown} />
            </div> : <div className='NavBarSide__chat--title-container'>
              <div className='NavBarSide__chat--title'>{chat.title}</div>
            </div>}
            {isActive && !isAnonymous &&
              (isEditing ? <>
                <div className='NavBarSide__chat--icon' onClick={handleSubmit}><AiOutlineCheck /></div>
                <div className='NavBarSide__chat--icon' onClick={() => setIsEditing(false)}><AiOutlineClose /></div>
              </> : <>
                <div className='NavBarSide__chat--icon' onClick={() => setIsEditing(true)}><AiOutlineEdit /></div>
                <div className='NavBarSide__chat--icon' onClick={() => setShowDeleteChatModal(true)}><RiDeleteBin5Line /></div>
              </>)
            }
          </div>
        </div>
      })}
    </div>
    <div className={classNames('NavBarSide__footer', { 'isExpanded': isExpanded })}>
      {isExpanded && <div className='NavBarSide__user-dropdown'>
        {/* <div className='NavBarSide__user-menu'>
          <div className='NavBarSide__user-menu--icon setting'></div>
          <div className='NavBarSide__user-menu--name'>{t('Settings')}</div>
        </div> */}
        <div className='NavBarSide__user-menu' onClick={handleLogout}>
          <div className='NavBarSide__user-menu--icon logout'></div>
          <div className='NavBarSide__user-menu--name'>{t('Log out')}</div>
        </div>
      </div>}
      {userName === 'ausr' ? <div className='NavBarSide__user'>
        <Link to='../signup'>
          <div className='NavBarSide__user--signup'>{t('Sign up')}</div>
        </Link>
      </div> : <div className='NavBarSide__user'>
        <div className='NavBarSide__user--section' onClick={() => setIsExpanded(!isExpanded)}>
          <div className='NavBarSide__user--icon'>{userName.charAt(0).toUpperCase()}</div>
          <div className='NavBarSide__user--name'>{userName}</div>
        </div>
      </div>}
    </div>
    {
      showShareLinkModal && <ShareLinkModal onClose={() => setShowShareLinkModal(false)} handleCopy={handleCopy}
        loadingState={loadingState} chatTitle={curChatTitle} chatHistory={chatHistory} curChat={curChat} />
    }
    {
      showDeleteChatModal && <DeleteChatModal onClose={() => setShowDeleteChatModal(false)} chatTitle={curChatTitle}
        curChat={curChat} handleDeleteChat={handleDeleteChat} />
    }
  </div >
}

export default React.memo(NavBarSide);
