import { useEffect, useState } from 'react';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { BsLink45Deg, BsCheck2Square } from "react-icons/bs";
import { getFormattedDate } from '../utils/helper';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Markup } from 'react-render-markup';
import { LoadingState } from '../utils/constants';
import Loader from './Loader';
import { post, del, authHeader } from '../service/fetch';
import { RiDeleteBin5Line } from "react-icons/ri";
import config from '../config';

const ShareLinkModal = props => {
  const { onClose, handleCopy, loadingState, chatHistory, chatTitle, curChat } = props;
  const [isLinkExist, setIsLinkExist] = useState(false);
  const userName = localStorage.getItem('username');
  const { t } = useTranslation();
  const date = getFormattedDate();
  const [history, setHistory] = useState([])

  useEffect(() => {
    checkLink();

    async function checkLink() {
      try {
        const response = await post({
          url: "/checkShareUrl",
          body: {
            chatId: curChat
          },
          headers: authHeader()
        })
        if (response.status === 'success') {
          let exist = response.message === 'exist'
          setIsLinkExist(exist)
          if (exist) {
            const response = await post({
              url: "/loadShareHistory",
              body: {
                historyId: curChat
              }
            })
            setHistory(response.history)
          } else {
            setHistory(chatHistory)
          }
        }
      } catch (e) {
        if (e.code === 401) {
          navigator('/login')
        }
      }
    }
  }, [curChat])
  const handleDelete = async () => {
    const url = "/deleteShareUrl/" + curChat;

    const response = await del({
      url: url,
      headers: authHeader()
    })
    if (response.status === 'success') {
      onClose();
      setTimeout(() => {
        alert("Share link deleted!");
      }, 1000)
    }
  }
  const handleLocalCopy = () => {
    if (isLinkExist) {
      const host = config.fe.host || 'http://localhost';
      const port = config.fe.port || '3000'
      const shareLink = port === 443 ? `${host}/share/${curChat}` : `${host}:${port}/share/${curChat}`;
      setTimeout(() => {
        onClose();
        copyToClipboard(shareLink)
      }, 1000)
    } else {
      handleCopy();
    }
  }
  const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setTimeout(() => {
      alert(t("Copied share link") + " " + text + " " + t("to clipboard") + t("!"));
    }, 1000)
  };

  const host = config.fe.host || 'http://localhost';
  const port = config.fe.port || '3000'
  const shareLink = port === 443 ? `${host}/share/${curChat}` : `${host}:${port}/share/${curChat}`;


  return <Modal className="share-link-modal" onClose={onClose}>
    <div className="modal-title">{t('Share Link')}</div>
    <div className="modal-hr"></div>
    <div className="modal">
      <div className='share-link-modal__content'>
        <div className='share-link-modal__header'>
          {isLinkExist ?
            <>
              {t("You have shared this chat") + " "}
              <a href={shareLink} target="_blank" rel="noreferrer">{t("before")}</a>
              {t(".") + t("If you want to update the shared chat content") + t(",") + " "}
              <span onClick={handleDelete}>{t("delete this link")}</span>
              {" " + t("and create a new shared link.")}
            </> : <>
              {t("Messages you send after creating your link won't be shared. Anyone with the URL will be able to view the shared chat.")}
            </>}
        </div>
        <div className='share-link-modal__body'>
          <div className='share-link-modal__chat-wrap'>
            <div className='share-link-modal__chat-history standard-scrollbar'>
              {history.map((chat, i) => <div key={i}>
                {chat.question && <div className='share-link-modal__chat-row question'>
                  <div className='share-link-modal__chat-sender'>
                    {userName.charAt(0).toUpperCase()}
                  </div>
                  <div className='share-link-modal__chat-content'>
                    <Markdown remarkPlugins={[remarkGfm]} className="markdown" rehypePlugins={[rehypeRaw]}>
                      {chat.question}
                    </Markdown>
                  </div>
                </div>}
                {chat.response && <div className='share-link-modal__chat-row'>
                  <div className='share-link-modal__chat-sender system'>
                  </div>
                  <div className='share-link-modal__chat-content'>{
                    <Markup markup={chat.response} />
                  }</div>
                </div>}
              </div>
              )}
            </div>
          </div>
          <div className='share-link-modal__chat-footer'>
            <div className='share-link-modal__chat-info'>
              <div className='share-link-modal__chat-name'>{chatTitle}</div>
              <div className='share-link-modal__chat-date'>{date}</div>
            </div>
            <div className='share-link-modal__chat-icon'>
              {isLinkExist && <div className='share-link-modal__chat-delete' onClick={handleDelete}>
                <RiDeleteBin5Line />
              </div>}
            </div>
          </div>
        </div>
        <div className='share-link-modal__footer'>
          <CopyButton loadingState={loadingState} onClick={handleLocalCopy} />
          {/* <Button className={'share-link-modal__copy-button'} icon={<BsLink45Deg />} title={t('Copy Link')} onClick={handleCopy} /> */}
        </div>
      </div>
    </div>
  </Modal>
}

const CopyButton = props => {
  const { loadingState, onClick } = props;
  const { t } = useTranslation();

  return <div >
    {loadingState === LoadingState.INITIAL &&
      <Button className={'share-link-modal__copy-button'} icon={<BsLink45Deg />} title={t('Copy Link')} onClick={onClick} />
    }
    {loadingState === LoadingState.LOADING &&
      <Button className={'share-link-modal__copy-button loading'} icon={<Loader size={'xsmall'} color={'green'} />} title={t('Copying') + '...'} disabled={true} />
    }
    {loadingState === LoadingState.LOADED &&
      <Button className={'share-link-modal__copy-button loaded'} icon={<BsCheck2Square />} title={t('Copied')} disabled={true} />
    }
  </div>
}

export default ShareLinkModal;