import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';

import './styles/index.scss';
import LoginPage from './pages/Login';
import SignUpPage from './pages/SignUp';
import VerifyPage from './pages/Verify';
import classNames from 'classnames';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Dashboard from './pages/Dashboard';
import RequestResetPasswordPage from './pages/RequestResetPassword';
import ResetPasswordPage from './pages/ResetPassword';
import SharePage from './pages/Share';
import PrivateRoute from './components/PrivateRoute';


function App() {
  console.log('app')
  return (
    <I18nextProvider i18n={i18n}>
      <div className={classNames('App')}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
            <Route path="/login" element={<LoginPage />} exact />
            <Route path="/signup" element={<SignUpPage />} exact />
            <Route path="/verify" element={<VerifyPage />} exact />
            <Route path="/request-pwd-reset" element={<RequestResetPasswordPage />} exact />
            <Route path="/reset-pwd" element={<ResetPasswordPage />} exact />
            <Route path="/c/:chatId?" element={<PrivateRoute element={<Dashboard />} />} exact />
            <Route path="/share/:shareId?" element={<SharePage />} exact />

            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} exact />
          </Routes>
        </BrowserRouter>
      </div>
    </I18nextProvider>
  );
}

export default App;
