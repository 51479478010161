import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ element }) {
  const userId = localStorage.getItem('user-id');
  const username = localStorage.getItem('username');
  const token = localStorage.getItem('token');

  const isAuthenticated = userId && username && token;
  if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/login" />;
  }
}

export default PrivateRoute;
